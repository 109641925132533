import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { NumberHelper } from '@helpers/NumberHelper';
import { i18n } from '@i18n/i18n';
import { BatchDTO, Company, Packet, PacketDTO, UserExtended } from '@services/apis/generated';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByBatchState,
  getColorByDeliverySheetUrstammState,
  getIconByBatchState,
  getTextByBatchState,
  getTextByBatchType,
  getTextByDeliverySheetUrstammState,
  getTextByDsRecordingType
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterDSByNameOrCompany } from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  allPacketAreClosedOrSentInShippingNote,
  batchIsClosed,
  checkIfAtLeastOneDSIsSelected,
  convertDateToActualFormat,
  getCounterBySelected,
  getMandatorySymbol
} from '../../../utils/classes/UrstammUtilityFunctions';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleButton,
  UrstammStyleCollapsible,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import { PacketImageForm } from '../packet/PacketDetailsView';
import PacketListView from '../packet/PacketListView';
import {
  CertificationProps,
  CompanyProductTypeProps,
  CompanySurfaceProps,
  PacketForm,
  SpeciesProps,
  TrunkProps
} from '../packet/PacketRegistrationView';
import { BatchForm } from './BatchRegistrationView';

export default function BatchDetailsView(props: {
  navigation: any;
  batchSelectedDTO: BatchDTO;
  customDSList: any; // (selected: boolean)
  moreItems: () => void;
  userExtendedMe?: UserExtended;
  deliverySheetSelected: (data: any) => void;
  updateBatch: (batchDTO: BatchDTO) => void;
  resetDSSelected?: () => void;
  confirmDSSelected?: () => void;
  packetList: Packet[];
  packetSelected: (packetSelected: Packet) => void;
  applyOrder: (orderSelected: ButtonSelect[]) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  validate: (packet: Packet) => void;
  processorList: Company[];
  certificationProps?: CertificationProps;
  speciesProps?: SpeciesProps;
  trunkProps?: TrunkProps;
  companyProductTypeProps?: CompanyProductTypeProps;
  companySurfaceProps?: CompanySurfaceProps;
  submitPacketForm: (data: PacketForm, packetPhoto?: PacketImageForm) => void;
  closeModalClosingPacket: boolean;
  closingBatch: (batchDTO: BatchDTO) => void;
  clonePacket?: (packet: Packet | PacketDTO) => void;
}) {
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [batchSelected, setBatchSelected] = useState<BatchDTO>();
  const [deliverySheetList, setDeliverySheetList] = useStateWithCallbackLazy<any>(() => {});
  const [showDeliverySheetSelect, setShowDeliverySheetSelect] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [openCloseBatchModal, setOpenCloseBatchModal] = useState<boolean>(false);

  const [batchForm, setBatchForm] = useStateWithCallbackLazy<BatchForm>({
    batch: {
      uniqueIdentifier: '',
      name: '',
      creationDate: new Date(),
      recordingType: undefined,
      comment: ''
    }
  });

  let dsKeys: ListSubData[] = [
    // { title: undefined, titleTextStyle: UrstammStyleList.cardTitleTextStyle, key: 'currentUrstammState', keyId: '1currentUrstammState', dataContainerViewStyle: { height: 24, flexBasis: '50%' }, isEnum: true },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.logging') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'logging',
      secondKey: 'name',
      keyId: '1logging',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.recording_type') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'recordingType',
      keyId: '1recordingType',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' },
      isEnum: true
    },
    {
      title: i18n.t('generics.trunks') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'trunks',
      secondKey: 'length',
      keyId: '1trunks',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.forestry') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'forestry',
      secondKey: 'name',
      keyId: '1forestry',
      // dataContainerViewStyle: { height: 24, flexBasis: '100%' }
    }
  ];

  useEffect(() => {
    if (props.batchSelectedDTO) {
      setBatchSelected(props.batchSelectedDTO);
      setBatchForm(
        {
          batch: {
            name: props.batchSelectedDTO.name,
            creationDate: props.batchSelectedDTO.creationDate,
            comment: props.batchSelectedDTO.comment
          }
        },
        updatedBatchForm => {
          saveButtonDisabled(errors, updatedBatchForm);
        }
      );
    }
  }, [props.batchSelectedDTO]);

  useEffect(() => {
    if (props.customDSList && props.customDSList.length > 0)
      setDeliverySheetList(props.customDSList, updatedDeliverySheetList => {
        saveButtonDisabled(errors, updatedDeliverySheetList);
      });
  }, [props.customDSList]);

  /**
   *
   * @param key
   * @param value
   */
  const updateBatchForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, batchForm, 'batch');
    setBatchForm(
      { batch: form['batch'] },
      mustValidate ? updatedBatchForm => validate(value, key, updatedBatchForm) : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: []
  });

  const validate = (value: string, key: string, updatedTreeForm: BatchForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedDSList: any) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated)) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const batchHasAtLeastOneDS = (batchDTO: BatchDTO): boolean => {
    let deliverySheetList = batchDTO?.deliverySheets ? [...batchDTO?.deliverySheets] : [];
    if (deliverySheetList.length == 0) return true;

    return false;
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/**Status and creation date */}
        <View style={UrstammStyleLayout.formElementContainer}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {batchSelected?.currentState ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                {batchSelected?.currentState && getIconByBatchState(batchSelected?.currentState as any)}
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateTextWithIcon,
                    getColorByBatchState(batchSelected.currentState)
                  ]}>
                  {getTextByBatchState(batchSelected.currentState!)}
                </Text>
              </View>
            ) : null}
            {batchSelected?.creationDate ? (
              <View style={UrstammStyle.flexRow}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creationDate') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {convertDateToActualFormat(Number(batchSelected.creationDate))}
                </Text>
              </View>
            ) : null}
          </View>
          <View style={UrstammStyleLayout.formElementContainerPd8}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {batchSelected?.packets ? (
                <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.amount_of_packet') + ': '}</Text>
                  <Text style={[UrstammStyle.dataStyle]}>{batchSelected.packets.size}</Text>
                </View>
              ) : null}
              {batchSelected?.recordingType ? (
                <View style={UrstammStyle.flexRow}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.type_of_record') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>{getTextByBatchType(batchSelected.recordingType)}</Text>
                </View>
              ) : null}
            </View>
          </View>
          <View style={UrstammStyleLayout.formElementContainerPd8}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {batchSelected?.sumCubage ? (
                <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('views.batch.sum_volume_packets') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>
                    {NumberHelper.roundWithThreeDecimals(batchSelected?.sumCubage)}
                  </Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>

        <View style={UrstammStyle.divider} />

        {props.batchSelectedDTO && allPacketAreClosedOrSentInShippingNote(props.batchSelectedDTO) ? (
          <View style={[{ marginTop: 8 }]}>
            <UrstammButtonBase
              testID={'close_batch'}
              text={i18n.t('views.batch.close_batch')}
              style={mainGreenButton}
              onSubmit={() => setOpenCloseBatchModal(true)}
              disabled={batchHasAtLeastOneDS(props.batchSelectedDTO)}
            />
          </View>
        ) : null}

        {/**Edit DS Collapsible */}
        <View style={{ marginTop: 8 }}>
          <View
            style={[
              UrstammStyleButton.smallBaseButtonStructure,
              { backgroundColor: ColorTheme.yellow },
              UrstammStyle.baseShadow
            ]}>
            <View style={UrstammStyleCollapsible.selectMainContainer}>
              <TouchableOpacity
                style={UrstammStyleCollapsible.selectCollapsible}
                onPress={() => setCollapsed(!collapsed)}>
                <Text style={UrstammStyleButton.baseButtonBWStyle}>{i18n.t('generics.edit')}</Text>

                <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={'white'} />
              </TouchableOpacity>
            </View>
          </View>

          <View style={[{ marginTop: 4 }]}>
            <Collapsible
              duration={500}
              easing={'easeInBounce'}
              collapsed={collapsed}
              style={UrstammStyleCollapsible.containerCollapsible}>
              <ScrollView
                keyboardShouldPersistTaps="always"
                contentContainerStyle={[
                  {
                    flexGrow: 1,
                    flexDirection: 'column',
                    paddingBottom: 40
                  }
                ]}>
                {/** Batch name */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
                  <UrstammInput
                    testID="batch_name"
                    style={{
                      containerViewStyle: UrstammStyleContainer.inputContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    value={batchForm.batch.name ? batchForm.batch.name : ''}
                    onChangeText={(text: string) => {
                      updateBatchForm('name', text, true);
                    }}
                    placeholder={i18n.t('views.batch.batch_registration.insert_batch_name_here')}
                    textInputTitle={i18n.t('views.batch.batch_registration.batch_name')}
                    mandatory={true}
                    editable={!batchIsClosed(props.batchSelectedDTO)}
                  />

                  {/**Validator */}
                  {errors['name'] && errors['name'].length > 0
                    ? errors['name'].map((error, idx) => {
                        return urstammUtilDisplayError(error, idx);
                      })
                    : null}
                </View>

                {/** Batch creation date (preset: today) */}
                <View style={UrstammStyleLayout.formElementContainerGrw1}>
                  <UrstammDatePicker
                    testID={'batch_date'}
                    containerStyle={UrstammStyleContainer.inputContainer}
                    fontStyle={UrstammStyle.formBaseLDarkGrey}
                    textInputTitle={i18n.t('generics.date')}
                    onChange={(dateTime: string) => updateBatchForm('creationDate', dateTime)}
                    icon={{
                      showIcon: true,
                      iconName: 'calendar',
                      iconColor: ColorTheme.lightGreen
                    }}
                    defaultValue={batchSelected?.creationDate}
                    placeholder={i18n.t('views.batch.batch_registration.insert_batch_date_here')}
                    mandatory={true}
                    mode={'date'}
                    disabled={batchIsClosed(props.batchSelectedDTO)}
                  />
                </View>

                {/** Delivery Sheet list */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  {/**Title */}
                  <View style={UrstammStyle.textTitleContainerFlex}>
                    <Text style={UrstammStyle.textTitle}>
                      {i18n.t('views.batch.batch_registration.choose_delivery_sheet') + getMandatorySymbol(true)}
                    </Text>
                  </View>

                  <UrstammButtonBase
                    testID={'choose_delivery_sheet'}
                    text={i18n.t('views.batch.batch_registration.choose_delivery_sheet')}
                    style={lightGreenBorderWhiteBGButton}
                    onSubmit={() => setShowDeliverySheetSelect(true)}
                    counter={getCounterBySelected(props.customDSList)}
                    disabled={batchIsClosed(props.batchSelectedDTO)}
                  />

                  {/**Delivery Sheet multiple select */}
                  {showDeliverySheetSelect ? (
                    <UrstammModalSelect
                      testID={'modal_batch_multiple_select'}
                      visible={showDeliverySheetSelect}
                      text={{
                        textInputTitle: i18n.t('views.batch.batch_registration.choose_delivery_sheet'),
                        textStyle: UrstammStyle.textTitle
                        //textInputSubTitle: i18n.t('views.batch.batch_registration.delivery_sheet_selected')+ ': ' + getCounterByDSSelected()
                      }}
                      closeModal={() => {
                        setShowDeliverySheetSelect(false), props.resetDSSelected ? props.resetDSSelected() : null;
                      }}
                      confirmButton={{
                        enabled: true,
                        text: i18n.t('generics.save'),
                        disabled: props.customDSList?.length == 0 ? true : false,
                        submitValue: () => {
                          setShowDeliverySheetSelect(false);
                          props.confirmDSSelected ? props.confirmDSSelected() : null;
                        }
                      }}
                      listJSX={
                        <UrstammList
                          elementSelected={(deliverySheetSelected: any) => {
                            props.deliverySheetSelected(deliverySheetSelected), saveButtonDisabled(errors, batchForm);
                          }}
                          testID={'delivery_sheet_list'}
                          textForEmptyList={i18n.t('views.delivery_sheet.delivery_sheets_not_found')}
                          list={props.customDSList}
                          listStyle={{
                            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                            bottomContainerViewStyle: UrstammStyleList.cardSubData,
                            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                            paddingBottom: 4
                          }}
                          listSubData={dsKeys}
                          moreItems={props.moreItems}
                          filterList={true}
                          multipleSelection={true}
                          filterListFunction={(text, data) =>
                            filterDSByNameOrCompany(text, data, props.userExtendedMe!)
                          }
                          currentState={{
                            //icon: (state) => getIconByLoggingState(state),
                            color: state => getColorByDeliverySheetUrstammState(state),
                            text: state => getTextByDeliverySheetUrstammState(state)
                          }}
                          titleKey={'name'}
                          getTextForData={(data, key) =>
                            key == 'currentUrstammState'
                              ? getTextByDeliverySheetUrstammState(data)
                              : getTextByDsRecordingType(data)
                          }
                          showHash={false}
                        />
                      }
                    />
                  ) : null}
                </View>

                {/** Comment */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammInput
                    testID="batch_comment"
                    style={{
                      containerViewStyle: UrstammStyleContainer.textareaContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    value={batchForm.batch.comment ? batchForm.batch.comment : ''}
                    onChangeText={(text: string) => updateBatchForm('comment', text, false)}
                    placeholder={i18n.t('generics.insert_comment_here')}
                    textInputTitle={i18n.t('generics.comment')}
                    mandatory={false}
                    multiline={true}
                    editable={!batchIsClosed(props.batchSelectedDTO)}
                  />
                </View>

                {!batchIsClosed(props.batchSelectedDTO) ? (
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammButtonBase
                      testID={'save_delivery_sheet'}
                      text={i18n.t('generics.save')}
                      style={mainGreenButton}
                      onSubmit={() => props.updateBatch(batchForm.batch)}
                      disabled={saveDisabled}
                    />
                  </View>
                ) : null}
              </ScrollView>
            </Collapsible>
          </View>

          {batchSelected ? (
            <View style={UrstammStyle.flex1}>
              <PacketListView
                navigation={props.navigation}
                batchSelected={props.batchSelectedDTO}
                packetList={props.packetList}
                packetSelected={(packetSelected: any) => props.packetSelected(packetSelected.item)}
                showSortBy={props.showSortBy}
                closeShowSortBy={props.closeShowSortBy}
                applyOrder={props.applyOrder}
                validate={(data: Packet) => props.validate(data)}
                processorList={props.processorList}
                certificationProps={props.certificationProps}
                speciesProps={props.speciesProps}
                trunkProps={props.trunkProps}
                companySurfaceProps={props.companySurfaceProps}
                submitPacketForm={props.submitPacketForm}
                companyProductTypeProps={props.companyProductTypeProps}
                closeModalClosingPacket={props.closeModalClosingPacket}
                clonePacket={props.clonePacket}
              />
            </View>
          ) : null}

          {openCloseBatchModal && (
            <UrstammModal
              testID={'close_batch_modal'}
              text={i18n.t('views.batch.are_you_sure_want_close_batch')}
              textStyle={UrstammStyleModal.formBtextModalStyle}
              visible={openCloseBatchModal}
              buttonText={{
                left: i18n.t('utility_components.modal.cancel'),
                right: i18n.t('utility_components.modal.confirm')
              }}
              confirm={(confirmed: boolean) => {
                confirmed ? props.closingBatch(props.batchSelectedDTO) : null, setOpenCloseBatchModal(false);
              }}
            />
          )}
        </View>
      </ScrollView>
    </View>
  );
}
