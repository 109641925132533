import UrstammImageBack from '@components/images/corner/UrstammImageBack';
import UrstammImageMenu from '@components/images/corner/UrstammImageMenu';
import UrstammImageNewTree from '@components/images/corner/UrstammImageNewTree';
import BaseLayoutCorner, { BaseLayoutOptions } from '@components/layouts/base/BaseLayoutCorner';
import {
  deepCopyAndAddSelectedParameter,
  restoreListToReference,
  selectElementFormList
} from '@components/utility-components/modal/UrstammModalSelect';
import UrstammTitle from '@components/utility-components/title/UrstammTitle';
import ShippingNoteRegistrationView from '@components/views/shipping-note/ShippingNoteRegistrationView';
import { AlertHelper } from '@helpers/AlertHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetShippingNoteList } from '@redux/features/shipping-note/shippingNoteSlice';
import { RootState } from '@redux/store';

import {
  customBatchResourceApi,
  customCompanyResourceApi,
  customPacketResourceApi,
  customShippingNoteResourceApi
} from '@services/apis/ApiConfiguration';

import {
  BatchDTO,
  Company,
  Packet,
  PacketCurrentStateEnum,
  PageBatch,
  PageCompany,
  ShippingNote,
  ShippingNoteCurrentStateEnum
} from '@services/apis/generated';

import { setBatchList, setBatchPage } from '@redux/features/batch/batchSlice';
import { setPacketPage } from '@redux/features/packet/packetSlice';
import { ShippingNoteDTO } from '@services/apis/generated/models/ShippingNoteDTO';
import React, { useCallback, useState } from 'react';
import { Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { RootStackScreenProps, UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { createOnlyIdBatch, createOnlyIdCompany } from '../../utils/classes/UrstammClassMapper';
import { generateSet, isCompanyMock, sleep } from '../../utils/classes/UrstammUtilityFunctions';
import { UrstammStyleCornerButton, UrstammStyleHeader, UrstammStyleLayout } from '../../utils/styles/UrstammStyle';

export default function ShippingNoteRegistrationContainer({
  navigation,
  route
}: RootStackScreenProps<'ShippingNoteRegistration'>) {
  const rdxBatchList = useSelector((state: RootState) => state.persistedReducer.batch.list);
  const rdxBatchCompleteList = useSelector((state: RootState) => state.persistedReducer.batch.completeList);
  const rdxBatchPage = useSelector((state: RootState) => state.persistedReducer.batch.page);

  const rdxPacketList = useSelector((state: RootState) => state.persistedReducer.packet.list);
  const rdxPacketCompleteList = useSelector((state: RootState) => state.persistedReducer.packet.completeList);
  const rdxPacketPage = useSelector((state: RootState) => state.persistedReducer.packet.page);

  const rdxBatchTotalPages = useSelector((state: RootState) => state.persistedReducer.batch.totalPages);
  const rdxPacketTotalPages = useSelector((state: RootState) => state.persistedReducer.packet.totalPages);
  const rdxBatchSortBy = useSelector((state: RootState) => state.persistedReducer.batch.sortBy);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const rdxMyCompany = useSelector((state: RootState) => state.persistedReducer.user.myCompany);
  const dispatch = useDispatch();

  const [shippingNoteList, setShippingNoteList] = useStateWithCallbackLazy<ShippingNote[]>([]);
  const [resetForm, setResetForm] = useState<boolean>(false);
  const [batchListState, setBatchListState] = useState<any>();
  const [batchSelected, setBatchSelected] = useState<any>();
  const [processorSelected, setProcessorSelected] = useState<Company>();
  const [packetList, setPacketList] = useState<any>();
  const [processorList, setProcessorList] = useState<Company[]>([]);
  const [batchListReference, setBatchListReference] = useState<any>();

  let cornerOption: BaseLayoutOptions = {
    cornerTopLeft: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.back'),
      icon: (
        <UrstammImageBack width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerTopRight: {
      showCorner: true,
      text: i18n.t('utility_components.corner_button.menu'),
      icon: (
        <UrstammImageMenu width={PlatformHelper.normalizeByDevice(34)} height={PlatformHelper.normalizeByDevice(24)} />
      )
    },
    cornerBottomLeft: {
      showCorner: false,
      text: i18n.t('utility_components.corner_button.sort_by'),
      icon: undefined
    },
    cornerBottomRight: {
      showCorner: false,
      text: undefined,
      icon: (
        <UrstammImageNewTree
          width={PlatformHelper.normalizeByDevice(34)}
          height={PlatformHelper.normalizeByDevice(24)}
        />
      )
    }
  };

  React.useEffect(() => {
    customBatchResourceApi
      .getPageBatchesForMyCompany({
        customBatchCriteria: { currentState: {} },
        page: rdxBatchPage,
        sort: ['creationDate,desc'],
        size: 1000
      })
      .then((batchList: PageBatch) => {
        dispatch(changeLoaderStatus(false));
        if (batchList) {
          dispatch(setBatchList(batchList.content));
          let list: BatchDTO[] = JSON.parse(JSON.stringify(batchList.content));

          setShippingNoteList(route.params?.shippingNoteList, updatedShippingNoteList => {
            deepCopyAndAddSelectedParameter(list, setBatchListState, setBatchListReference);
          });
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  }, []);

  React.useEffect(() => {
    if (batchSelected) {
      customPacketResourceApi
        .getPacketsByBatch({
          batchId: batchSelected.id,
          batchIdEquals: batchSelected.id,
          currentStateEquals: PacketCurrentStateEnum.Closed
        })
        .then((packetList: Packet[]) => {
          packetList = packetList.filter(
            item =>
              item.processor?.id == processorSelected?.id ||
              (isCompanyMock(item.processor) && isCompanyMock(processorSelected))
          );
          setPacketList(packetList);
        })
        .catch(async error => {
          dispatch(changeLoaderStatus(false));
          let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
          let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
          AlertHelper.showSimpleAlert('Error', errorJson);
        });
    }
  }, [batchSelected, processorSelected]);

  const batchSelectID = batch => {
    setBatchSelected(batch);
  };

  const processorSelectID = processor => {
    setProcessorSelected(processor);
  };

  const getAllCompanyProcessorConnectedToMe = () => {
    //let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
    dispatch(changeLoaderStatus(true));

    customCompanyResourceApi
      .getCompaniesConnectedToMe({
        //sort: sortBy,
        customCompanyCriteria: {},
        page: 0,
        size: 1000
      })
      .then((list: PageCompany) => {
        dispatch(changeLoaderStatus(false));

        if (list && list.content?.length) {
          list.content.push({ name: i18n.t('views.shipping_note.no_urstamm_processor'), id: -200 });
          setProcessorList(list.content);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  useFocusEffect(
    useCallback(() => {
      setResetForm(false);

      if (route.params?.shippingNoteList) {
        //TODO: Test
      }
    }, [route.params])
  );

  const saveShippingNote = (shippingNote: ShippingNoteDTO) => {
    let packetsSet: Set<Packet> = packetList ? new Set(generateSet(packetList)) : new Set();

    dispatch(changeLoaderStatus(true));

    let shippingNoteObj: ShippingNote = {
      name: shippingNote.name!,
      creationDate: shippingNote.creationDate,
      currentState: ShippingNoteCurrentStateEnum.InProgress,
      comment: shippingNote.comment!,
      batch: createOnlyIdBatch(shippingNote.batch),
      processor: createOnlyIdCompany(shippingNote.processor),
      packets: packetsSet
    };

    customShippingNoteResourceApi
      .createShippingNoteCustom({ shippingNote: shippingNoteObj })
      .then((shippingNote: ShippingNote) => {
        dispatch(changeLoaderStatus(false));
        if (shippingNote) {
          setResetForm(true);
          dispatch(resetShippingNoteList());
          UrstammNavigationHelper.navigateToShippingNoteList(navigation, true);
        }
      })
      .catch(async error => {
        dispatch(changeLoaderStatus(false));
        let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
        let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
        AlertHelper.showSimpleAlert('Error', errorJson);
      });
  };

  const goBack = async (): Promise<void> => {
    dispatch(resetShippingNoteList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToShippingNoteList(navigation, true);
  };

  const getMoreBatchItems = (): void => {
    if (rdxBatchPage < rdxBatchTotalPages - 1) {
      dispatch(setBatchPage(rdxBatchPage + 1));
    }
  };

  const getMorePacketItems = (): void => {
    if (rdxPacketPage < rdxPacketTotalPages - 1) {
      dispatch(setPacketPage(rdxPacketPage + 1));
    }
  };

  return (
    <BaseLayoutCorner
      navigation={navigation}
      cornerOptions={cornerOption}
      layoutStyle={{
        topContainer: UrstammStyleLayout.topContainerCorner,
        bottomContainer: UrstammStyleLayout.baseBottomContainer,
        bottomSubContainer: UrstammStyleLayout.bottomSubContainer40,
        headerContainer: UrstammStyleHeader.headerCenterLogo,
        headerDetails: UrstammStyleHeader.headerDetails,
        cornerBottomLeft: UrstammStyleCornerButton.cornerBottomLeft40,
        cornerBottomRight: UrstammStyleCornerButton.cornerBottomRight40
      }}
      submitTopLeft={goBack}
      title={
        <UrstammTitle
          testID={'shipping_note_title'}
          text={i18n.t('views.shipping_note.shipping_note_registration.new_shipping_note')}
          fontStyle={UrstammStyleHeader.headerTextStyleBlack}
        />
      }
      view={
        <ShippingNoteRegistrationView
          navigation={navigation}
          submitShippingNoteForm={(data: ShippingNoteDTO) => saveShippingNote(data)}
          resetShippingNoteForm={resetForm}
          batchProps={{
            batchList: batchListState,
            batchSelected: batchSelected => selectElementFormList(batchSelected, setBatchListState, batchListState),
            moreItems: getMoreBatchItems,
            resetBatchSelected: () => restoreListToReference(setBatchListState, batchListReference),
            batchSelectedID: batch => batchSelectID(batch)
          }}
          processorProps={{
            getAll: getAllCompanyProcessorConnectedToMe,
            processorList: processorList,
            processorSelectedID: processor => processorSelectID(processor)
          }}
          packetProps={{
            packetList: packetList,
            packetSelected: packetSelected => selectElementFormList(packetSelected, setPacketList, packetList),
            moreItems: getMorePacketItems
          }}
          userExtendedMe={rdxUserExtendedMe}
        />
      }
    />
  );
}
