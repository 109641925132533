import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import { SlideButtonOption } from '@components/utility-components/button/UrstammButtonFilter';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import { AlertHelper } from '@helpers/AlertHelper';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import { LoggingCurrentStateEnum } from '@services/apis/generated';
import React, { useEffect, useState } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { getTextByLoggingState } from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  urstammUtilDisplayError,
  urstammUtilUpdatePhoto,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import { UrstammStyle, UrstammStyleContainer, UrstammStyleLayout } from '../../../utils/styles/UrstammStyle';

export interface Logging {
  id?: number;
  name?: string;
  creationDate?: Date;
  comment?: string;
  estimatedVolume?: number;
}

export interface LoggingForm {
  logging: Logging;
  permit: string;
  base64File: string;
}

export default function LoggingRegistrationView(props: {
  navigation: any;
  submitLoggingForm: (form: any) => void;
  mediaType: (type: string) => void;
  clearForm: boolean;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);

  const [filterList, setFilterList] = useState<SlideButtonOption[]>([]);

  const [loggingForm, setLoggingForm] = useStateWithCallbackLazy<LoggingForm>({
    logging: {
      name: '',
      creationDate: new Date(),
      comment: '',
      estimatedVolume: undefined
    },
    permit: '',
    base64File: ''
  });

  useEffect(() => {
    getFilter();
    return () => {};
  }, []);

  const getFilter = (): SlideButtonOption[] => {
    let filterList: SlideButtonOption[] = [];
    Object.values(LoggingCurrentStateEnum).map(state => {
      let type: SlideButtonOption = {
        id: state,
        text: getTextByLoggingState(state),
        active: false
      };
      filterList.push(type);
    });
    filterList[0].active = true;
    setFilterList(filterList);
    return filterList;
  };

  /**
   *
   * @param key
   * @param value
   */
  const updateLoggingForm = (key: string, value: any, mustValidate?: boolean): void => {
    let logging = { ...loggingForm.logging };
    let permit = loggingForm.permit;
    let base64File = loggingForm.base64File;

    if (key == 'permit') permit = value;

    const formKey = key as keyof Logging;
    logging[formKey] = value;
    setLoggingForm(
      { logging: logging, permit: permit, base64File: base64File },
      mustValidate ? updatedLoggingForm => validate(value, key, updatedLoggingForm) : () => {}
    );
  };

  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  //Validators
  const validators = useState<any>({
    name: { required: true },
    estimatedVolume: { numeric_no_digits: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    estimatedVolume: []
  });

  const validate = (value: string, key: string, updatedLoggingForm: LoggingForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedLoggingForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedLoggingForm: LoggingForm) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated) || updatedLoggingForm.logging.name?.length == 0) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const updatePhoto = (base64: string, location: any, mustValidate?: boolean, isLoggingPhoto?: boolean): void => {
    const photoCreationDate = new Date();
    let form = urstammUtilUpdatePhoto(base64, location, photoCreationDate, loggingForm, 'logging');
    setLoggingForm(
      { logging: form['logging'], base64File: form['base64File'], permit: form['permit'] },
      () => {}
    );
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/** Name */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammInput
            testID="logging_name"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{
              showIcon: false,
              iconName: 'user',
              iconColor: ColorTheme.lightGreen
            }}
            onChangeText={(text: string) => {
              updateLoggingForm('name', text, true);
            }}
            placeholder={i18n.t('views.logging.logging_registration.insert_logging_name_here')}
            textInputTitle={i18n.t('generics.name')}
            mandatory={true}
          />

          {/**Validator */}
          {errors['name'] && errors['name'].length > 0
            ? errors['name'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Date Picker */}
        <View style={UrstammStyleLayout.formElementContainerGrw1}>
          <UrstammDatePicker
            testID={'logging_date'}
            containerStyle={UrstammStyleContainer.inputContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            textInputTitle={i18n.t('generics.date')}
            onChange={(dateTime: string) => updateLoggingForm('creationDate', dateTime)}
            icon={{
              showIcon: true,
              iconName: 'calendar',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.logging.logging_registration.insert_logging_date_here')}
            mode={'date'}
          />
        </View>

        {/**Image picker */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammFilePicker
            testID="logging_image_picker"
            inputStyle={UrstammStyleContainer.cameraContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            icon={{
              showIcon: true,
              iconName: 'camera',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('generics.upload_photo_file')}
            textInputTitle={i18n.t('views.logging.logging_registration.logging_photo')}
            uploadType={UploadType.camera}
            deleteBase64={() => {
              updateLoggingForm('base64File', '');
            }}
            mediaTypeUploaded={PickerMediaType.Image}
            clearPicker={props.clearForm}
            saveBase64={(base64: string, location: any) => {
              if (!location) {
                AlertHelper.showSimpleAlert('Error', 'cannot_take_gps_coordinates');
                updateLoggingForm('base64File', '', true);
              }
              updatePhoto(base64, location, true, true);
            }}
          />

          {/**Validator */}
          {errors['base64File'] && errors['base64File'].length > 0
            ? errors['base64File'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/** Comment */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="logging_comment"
            style={{
              containerViewStyle: UrstammStyleContainer.textareaContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            onChangeText={(text: string) => updateLoggingForm('comment', text)}
            placeholder={i18n.t('generics.insert_comment_here')}
            textInputTitle={i18n.t('generics.comment')}
            mandatory={false}
            multiline={true}
          />
        </View>

        {/** Permit files */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammFilePicker
            testID="logging_photo_picker"
            inputStyle={UrstammStyleContainer.cameraContainer}
            fontStyle={UrstammStyle.formBaseLDarkGrey}
            icon={{
              showIcon: true,
              iconName: 'camera',
              iconColor: ColorTheme.lightGreen
            }}
            placeholder={i18n.t('views.logging.logging_registration.upload_permit_file')}
            textInputTitle={i18n.t('views.logging.logging_registration.permit')}
            uploadType={UploadType.all}
            mandatory={false}
            mediaTypePicked={(type: string) => props.mediaType(type)}
            deleteBase64={() => updateLoggingForm('permit', undefined)}
            saveBase64={(base64: string) => updateLoggingForm('permit', base64)}
          />
        </View>

        {/** Estimated volume */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammInput
            testID="estimated_volume_pile"
            style={{
              containerViewStyle: UrstammStyleContainer.inputContainer,
              textStyle: UrstammStyle.formBaseLDarkGrey
            }}
            icon={{ showIcon: false }}
            keyboardType={'numeric'}
            placeholder={i18n.t('generics.insert_estimated_volume_here')}
            textInputTitle={i18n.t('generics.estimated_logging_volume')}
            onChangeText={(text: string) => updateLoggingForm('estimatedVolume', text, true)}
            mandatory={false}
          />
          {/**Validator */}
          {errors['estimatedVolume'] && errors['estimatedVolume'].length > 0
            ? errors['estimatedVolume'].map((error, idx) => {
                return urstammUtilDisplayError(error, idx);
              })
            : null}
        </View>

        {/**Create logging */}
        <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
          <UrstammButtonBase
            testID={'save_logging'}
            text={i18n.t('generics.save')}
            style={mainGreenButton}
            onSubmit={() => props.submitLoggingForm(loggingForm)}
            disabled={saveDisabled || rdxLoaderStatus}
          />
        </View>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({});
