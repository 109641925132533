import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import UrstammFilePicker, {
  PickerMediaType,
  UploadType
} from '@components/utility-components/file-picker/UrstammFilePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { NumberHelper } from '@helpers/NumberHelper';
import { i18n } from '@i18n/i18n';
import { RootState } from '@redux/store';
import { Product, Project, ProjectCurrentStateEnum, ProjectDTO } from '@services/apis/generated';
import React, { useEffect, useMemo, useState } from 'react';
import { Image, Modal, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Layout from '../../../config/Layout';
import {
  getColorByProductState,
  getColorByProjectState,
  getIconByProjectState,
  getTextByProductState,
  getTextByProjectState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import { filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor } from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  convertDateToActualFormat,
  getCardTitleByProcessorBatchOrProduct,
  getCounterBySelected,
  getMandatorySymbol,
  projectIsEditable
} from '../../../utils/classes/UrstammUtilityFunctions';
import { buildProjectPhotoUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleButton,
  UrstammStyleCollapsible,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import { ProductProps, ProjectForm, ProjectImageForm } from './ProjectRegistrationView';

interface ProductListProps {
  productSelected: (product?: Product) => void;
}

export default function ProjectDetailsView(props: {
  navigation: any;
  projectSelected: Project | ProjectDTO;
  submitProjectForm: (data: ProjectForm, projectPhoto?: ProjectImageForm) => void;
  closingProject?: (project: Project | ProjectDTO) => void;
  productsPros: ProductProps;
  productListProps?: ProductListProps;
  readOnly?: boolean;
}) {
  const rdxLoaderStatus = useSelector((state: RootState) => state.persistedReducer.loader.enabled);
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [showPreview, setShowPreview] = useState<any>(false);
  const [photoPreview, setPhotoPreview] = useState<string>('');
  const [showCloseProjectConfirm, setShowCloseProjectConfirm] = useState<boolean>(false);
  const [sourceProductList, setSourceProductList] = useStateWithCallbackLazy<any>([]);
  const [showSourceProductSelect, setShowSourceProductSelect] = useState<boolean>(false);
  const [openCloseProjectModal, setOpenCloseProjectModal] = useState<boolean>(false);
  const [photoSelected, setPhotoSelected] = useState<string | undefined>(undefined);

  const editable = useMemo(() => {
    return !props.readOnly;
  }, [!props.readOnly]);

  const [projectForm, setProjectForm] = useStateWithCallbackLazy<ProjectForm>({
    project: {
      uniqueNumber: props.projectSelected?.uniqueNumber ? props.projectSelected?.uniqueNumber : '',
      name: '',
      creationDate: new Date(),
      currentState: ProjectCurrentStateEnum.InProgress,
      comment: '',
      sumCubage: props.projectSelected?.sumCubage ? props.projectSelected?.sumCubage : 0
    }
  });

  const [projectImageForm, setProjectImageForm] = useStateWithCallbackLazy<ProjectImageForm>({});

  const hasPhotoSelected = useMemo(() => {
    if ((props.projectSelected?.photoFilepath?.length || 0) > 0 && !projectImageForm.deletePrevious) return true;
    if ((projectImageForm.base64File?.length || 0) > 0) return true;
    return false;
  }, [props.projectSelected?.photoFilepath, projectImageForm.base64File]);

  let sourceProductsKeys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.volume_with_unit') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-start', flexWrap: 'wrap', alignItems: 'center' },
      truncateMaxLength: 999,
      isEnum: true
    }
  ];

  let productsKeys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate'
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataContainerViewStyle: { height: 24, flexBasis: '100%' },
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('generics.volume_with_unit') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage'
      // dataContainerViewStyle: { height: 24, flexBasis: '100%' }
    },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' },
      // dataContainerViewStyle: { height: 24, flexBasis: '100%' },
      truncateMaxLength: 16,
      isEnum: true
    }
  ];

  useEffect(() => {
    if (props.projectSelected?.photoFilepath) {
      buildProjectPhotoUrl(props.projectSelected).then(res => {
        setPhotoSelected(res);
        setPhotoPreview(res);
      });
    }
    setProjectForm(
      {
        project: {
          currentState: ProjectCurrentStateEnum.InProgress,
          uniqueNumber: props.projectSelected?.uniqueNumber!,
          name: props.projectSelected?.name!,
          creationDate: props.projectSelected?.creationDate,
          comment: props.projectSelected?.comment,
          processor: props.projectSelected?.processor,
          sumCubage: props.projectSelected?.sumCubage,
          latitude: props.projectSelected?.latitude,
          longitude: props.projectSelected?.longitude
        }
      },
      projectUpdated => {
        saveButtonDisabled(errors, projectUpdated, projectImageForm);
      }
    );
  }, [props.projectSelected]);

  const products = useMemo(() => {
    if (props.projectSelected?.products) {
      if (props.projectSelected?.products instanceof Set) {
        return [...props.projectSelected?.products.values()];
      } else {
        return [...props.projectSelected?.products];
      }
    }
    return [];
  }, [props.projectSelected?.products]);

  useEffect(() => {
    if (props.productsPros.list) {
      setSourceProductList(props.productsPros.list, updatedList => {
        saveButtonDisabled(errors, projectForm, projectImageForm, updatedList);
      });
    }
  }, [props.productsPros.list]);

  const projectHasAtLeastOneProduct = (project: Project | ProjectDTO): boolean => {
    let products = project?.products ? [...project?.products] : [];
    return products.length > 0;
  };

  /**
   *
   * @param key
   * @param value
   */
  const updateProjectForm = (key: string, value: any, mustValidate?: boolean): void => {
    let project = { ...projectForm.project };

    const formKey = key as keyof any; //TODO: add interface
    project[formKey] = value;

    return setProjectForm(
      { project: project },
      mustValidate
        ? updatedLoggingForm => {
            validate(value, key, updatedLoggingForm);
          }
        : () => {}
    );
  };

  const updatePhoto = (base64: string, location: any, uri: any, mustValidate?: boolean): void => {
    let photo: ProjectImageForm = {
      base64File: base64,
      longitude: location.coords.longitude,
      latitude: location.coords.latitude,
      photoCreationDate: new Date()
    };
    setProjectImageForm(photo, projectImageFormUpdated => {
      setPhotoSelected(uri);
      saveButtonDisabled(errors, projectForm, projectImageFormUpdated);
    });
  };

  const deletePhoto = async () => {
    let photo: ProjectImageForm = {
      base64File: undefined,
      longitude: undefined,
      latitude: undefined,
      deletePrevious: !!props.projectSelected?.photoFilepath
    };
    setPhotoSelected(undefined);
    setProjectImageForm(photo, projectImageFormUpdated => {
      saveButtonDisabled(errors, projectForm, projectImageFormUpdated);
    });
  };

  //Validators
  const validators = {
    name: { required: true },
    base64File: { required: false },
    latitude: { required: false },
    longitude: { required: false }
  };

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: [],
    base64File: []
  });

  const validate = (value: string, key: string, updatedTreeForm: ProjectForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm, projectImageForm)
    );
  };

  const saveButtonDisabled = (
    errorsUpdated,
    updatedProjectForm?: ProjectForm,
    updatedImageForm?: ProjectImageForm,
    sourceProductList?: any[]
  ) => {
    let disabled: boolean = false;
    const proj = updatedProjectForm?.project;
    if (!proj || urstammUtilValidatorInError(errorsUpdated) || !proj.name) {
      disabled = true;
    }

    return setSaveDisabled(disabled);
  };

  const submitForm = () => {
    props.submitProjectForm(projectForm, projectImageForm);
  };

  const isProductFieldHiddenFunction = (item, key) => {
    const { sumCubage = null } = item;
    const sumCubageDeps = ['processor', 'productSpecies', 'productType'];
    if (NumberHelper.roundWithThreeDecimals(sumCubage) === 0 && sumCubageDeps.includes(key)) {
      return true;
    }
    return false;
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 40 }]}>
        {/* Preview modal */}
        {showPreview && (
          <View style={UrstammStyleModal.centeredView}>
            <Modal animationType="slide" transparent={true} visible={showPreview}>
              <View style={UrstammStyleModal.centeredView}>
                <View style={UrstammStyleModal.modalView}>
                  <View style={[styles.cameraTopButton]}>
                    {/**Close Preview */}
                    <TouchableOpacity
                      style={[styles.top_button, UrstammStyle.baseShadow, { alignSelf: 'flex-end' }]}
                      onPress={() => setShowPreview(false)}>
                      <View style={[styles.iconCloseContainer]}>
                        <UrstammIcon name={'close'} size={24} color={ColorTheme.mediumGrey} />
                      </View>
                    </TouchableOpacity>
                  </View>

                  {photoPreview.length > 0 && (
                    <Image
                      source={{ uri: photoPreview }}
                      style={{
                        width: Layout.window.width * 0.9,
                        height: Layout.window.height * 0.7
                      }}
                    />
                  )}
                </View>
              </View>
            </Modal>
          </View>
        )}

        {/**Status and creation date */}
        <View style={[UrstammStyleLayout.formElementContainer]}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {props.projectSelected?.currentState && (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                {props.projectSelected && getIconByProjectState(props.projectSelected?.currentState as any)}
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateTextWithIcon,
                    getColorByProjectState(props.projectSelected.currentState)
                  ]}>
                  {getTextByProjectState(props.projectSelected.currentState!)}
                </Text>
              </View>
            )}
            {props.projectSelected?.creationDate ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.flexEndX, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creation') + ' '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {convertDateToActualFormat(Number(props.projectSelected.creationDate))}
                </Text>
              </View>
            ) : null}
          </View>
        </View>

        <View style={UrstammStyleLayout.formElementContainer}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
              <Text style={UrstammStyle.titleStyle}>{i18n.t('views.project.amount_of_products') + ': '}</Text>
              <Text style={[UrstammStyle.dataStyle]}>{products.length}</Text>
            </View>

            <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
              <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.volume_with_unit') + ': '}</Text>
              <Text style={[UrstammStyle.dataStyle]}>
                {NumberHelper.roundWithThreeDecimals(props.projectSelected?.sumCubage)}
              </Text>
            </View>
          </View>
        </View>

        <View style={[UrstammStyleLayout.formElementContainer, { flexWrap: 'nowrap' }]}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap, { flexWrap: 'nowrap' }]}>
            {props.projectSelected?.processor ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.processor') + ': '}</Text>
                <Text style={[UrstammStyle.dataStyle]}>{props.projectSelected?.processor.name}</Text>
              </View>
            ) : null}
          </View>
        </View>

        <View style={UrstammStyleLayout.formElementContainer}>
          <View style={UrstammStyleLayout.formElementContainerPd8}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {props.projectSelected?.comment ? (
                <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.comment') + ': '}</Text>
                  <Text style={[UrstammStyle.dataStyle]}>{props.projectSelected.comment}</Text>
                </View>
              ) : null}
            </View>

            <View style={UrstammStyleLayout.formElementContainerPd8}>
              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexEndX, { marginRight: 8 }]}>
                {photoPreview ? (
                  <View>
                    {photoPreview ? (
                      <TouchableOpacity style={styles.imagePreview} onPress={() => setShowPreview(true)}>
                        <Image source={{ uri: photoPreview }} style={{ width: 52, height: 52 }} />
                      </TouchableOpacity>
                    ) : null}
                  </View>
                ) : null}
              </View>
            </View>
          </View>
        </View>

        <View style={UrstammStyle.divider} />

        {props.projectSelected && props.projectSelected.currentState != ProjectCurrentStateEnum.Closed ? (
          <View style={[{ marginTop: 8 }]}>
            <UrstammButtonBase
              testID={'close_project'}
              text={i18n.t('views.project.close_project')}
              style={mainGreenButton}
              onSubmit={() => setOpenCloseProjectModal(true)}
              disabled={!projectHasAtLeastOneProduct(props.projectSelected) || !props.projectSelected?.photoFilepath}
            />
          </View>
        ) : null}

        {showCloseProjectConfirm && (
          <UrstammModal
            testID={'close_logging_modal'}
            text={i18n.t('views.project.are_you_sure_want_close_project')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={showCloseProjectConfirm}
            buttonText={{
              left: i18n.t('utility_components.modal.cancel'),
              right: i18n.t('utility_components.modal.confirm')
            }}
            confirm={(confirmed: boolean) => {
              confirmed ? submitForm() : null, setShowCloseProjectConfirm(false);
            }}
          />
        )}

        {/**Edit DS Collapsible */}
        {props.projectSelected && editable && projectIsEditable(props.projectSelected) ? (
          <View style={{ marginTop: 8 }}>
            <View
              style={[
                UrstammStyleButton.smallBaseButtonStructure,
                { backgroundColor: ColorTheme.yellow },
                UrstammStyle.baseShadow
              ]}>
              <View style={UrstammStyleCollapsible.selectMainContainer}>
                <TouchableOpacity
                  style={UrstammStyleCollapsible.selectCollapsible}
                  onPress={() => setCollapsed(!collapsed)}
                  disabled={false}>
                  <Text style={UrstammStyleButton.baseButtonBWStyle}>{i18n.t('generics.edit')}</Text>

                  <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={'white'} />
                </TouchableOpacity>
              </View>
            </View>

            <View style={[{ marginTop: 4 }]}>
              <Collapsible
                duration={500}
                easing={'easeInBounce'}
                collapsed={collapsed}
                key={hasPhotoSelected ? 'with_photo' : 'without_photo'}
                style={UrstammStyleCollapsible.containerCollapsible}>
                <ScrollView
                  keyboardShouldPersistTaps="always"
                  contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column' }]}>
                  {/** Project name */}
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammInput
                      testID="project_name"
                      style={{
                        containerViewStyle: UrstammStyleContainer.inputContainer,
                        textStyle: UrstammStyle.formBaseLDarkGrey
                      }}
                      icon={{ showIcon: false }}
                      value={projectForm.project.name ? projectForm.project.name : ''}
                      onChangeText={(text: string) => {
                        updateProjectForm('name', text, true);
                      }}
                      placeholder={i18n.t('views.project.project_registration.insert_project_name_here')}
                      textInputTitle={i18n.t('views.project.project_registration.project_name')}
                      mandatory={true}
                      editable={projectIsEditable(props.projectSelected)}
                    />

                    {/**Validator */}
                    {errors['name'] && errors['name'].length > 0
                      ? errors['name'].map((error, idx) => {
                          return urstammUtilDisplayError(error, idx);
                        })
                      : null}
                  </View>

                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    {/**Title */}
                    <View style={UrstammStyle.textTitleContainerFlex}>
                      <Text style={UrstammStyle.textTitle}>
                        {i18n.t('views.project.project_registration.choose_products') + getMandatorySymbol(true)}
                      </Text>
                    </View>

                    <UrstammButtonBase
                      testID={'choose_products'}
                      text={i18n.t('views.project.project_registration.choose_products')}
                      style={lightGreenBorderWhiteBGButton}
                      onSubmit={() => setShowSourceProductSelect(true)}
                      counter={getCounterBySelected(sourceProductList)}
                    />

                    {/**Product Modal multiple select */}
                    {showSourceProductSelect ? (
                      <UrstammModalSelect
                        testID={'modal_products_multiple_select'}
                        visible={showSourceProductSelect}
                        text={{
                          textInputTitle: i18n.t('views.project.project_registration.choose_products'),
                          textStyle: UrstammStyle.textTitle
                        }}
                        closeModal={() => {
                          setShowSourceProductSelect(false),
                            props.productsPros.resetSelected ? props.productsPros.resetSelected() : null;
                        }}
                        confirmButton={{
                          enabled: true,
                          text: i18n.t('generics.save'),
                          submitValue: () => {
                            setShowSourceProductSelect(false);
                            props.productsPros.confirmSelected ? props.productsPros.confirmSelected() : null;
                          }
                        }}
                        listJSX={
                          <UrstammList
                            elementSelected={(productSelected: any) => {
                              props.productsPros.selected(productSelected);
                              saveButtonDisabled(errors, projectForm, projectImageForm, sourceProductList);
                            }}
                            showHash={false}
                            testID={'source_product_list'}
                            textForEmptyList={i18n.t('views.project.project_registration.products_not_found')}
                            titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
                            list={sourceProductList}
                            listStyle={{
                              mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                              mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                              mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                              bottomContainerViewStyle: UrstammStyleList.cardSubData,
                              bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                              paddingBottom: 4
                            }}
                            listSubData={sourceProductsKeys}
                            moreItems={props.productsPros.moreItems}
                            filterList={true}
                            multipleSelection={true}
                            filterListFunction={(text, data) =>
                              filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor(
                                text,
                                data,
                                rdxUserExtendedMe!
                              )
                            }
                            currentState={{
                              //icon: (state) => getIconByLoggingState(state),
                              color: state => getColorByProductState(state),
                              text: state => getTextByProductState(state)
                            }}
                          />
                        }
                      />
                    ) : null}
                  </View>

                  {/**Image picker */}
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammFilePicker
                      testID="project_image_picker"
                      inputStyle={UrstammStyleContainer.cameraContainer}
                      fontStyle={UrstammStyle.formBaseLDarkGrey}
                      locationProps={
                        (projectImageForm?.longitude || props.projectSelected?.longitude) &&
                        (projectImageForm?.latitude || props.projectSelected?.latitude)
                          ? {
                              latitude: Number(projectImageForm?.latitude || props.projectSelected?.latitude),
                              longitude: Number(projectImageForm?.longitude || props.projectSelected?.longitude)
                            }
                          : undefined
                      }
                      uri={photoSelected}
                      icon={{
                        showIcon: true,
                        iconName: 'camera',
                        iconColor: ColorTheme.lightGreen
                      }}
                      placeholder={i18n.t('generics.upload_photo_file')}
                      textInputTitle={i18n.t('views.project.project_registration.project_photo')}
                      uploadType={UploadType.camera}
                      mandatory={true}
                      deleteBase64={deletePhoto}
                      disabled={!projectIsEditable(props.projectSelected)}
                      mediaTypeUploaded={PickerMediaType.Image}
                      saveBase64={(base64: string, location: any, uri: any) => {
                        updatePhoto(base64, location, uri, true);
                      }}
                    />

                    {/**Validator */}
                    {errors['base64File'] && errors['base64File'].length > 0
                      ? errors['base64File'].map((error, idx) => {
                          return urstammUtilDisplayError(error, idx);
                        })
                      : null}
                  </View>

                  {/** Comment */}
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammInput
                      testID="project_comment"
                      style={{
                        containerViewStyle: UrstammStyleContainer.textareaContainer,
                        textStyle: UrstammStyle.formBaseLDarkGrey
                      }}
                      icon={{ showIcon: false }}
                      value={projectForm.project.comment ? projectForm.project.comment : ''}
                      onChangeText={(text: string) => updateProjectForm('comment', text, false)}
                      placeholder={i18n.t('generics.insert_comment_here')}
                      textInputTitle={i18n.t('generics.comment')}
                      mandatory={false}
                      multiline={true}
                      editable={projectIsEditable(props.projectSelected)}
                    />
                  </View>

                  {/**Save */}
                  {projectIsEditable(props.projectSelected) ? (
                    <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                      <UrstammButtonBase
                        testID={'save_project'}
                        text={i18n.t('generics.save')}
                        style={mainGreenButton}
                        onSubmit={submitForm}
                        disabled={saveDisabled || rdxLoaderStatus}
                      />
                    </View>
                  ) : null}
                </ScrollView>
              </Collapsible>
            </View>
          </View>
        ) : null}

        {props.projectSelected ? (
          <View style={UrstammStyle.flex1}>
            <UrstammList
              elementSelected={(productSelected: any) => {
                props.productListProps?.productSelected?.(productSelected?.item);
              }}
              showHash={false}
              testID={'product_list'}
              titleByFunction={data => getCardTitleByProcessorBatchOrProduct(data)}
              textForEmptyList={i18n.t('views.project.products_not_found')}
              list={products || []}
              listStyle={{
                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                paddingBottom: 4
              }}
              listSubData={productsKeys}
              isFieldHiddenFunction={isProductFieldHiddenFunction}
              filterList={true}
              filterListFunction={(text, data) =>
                filterProductByNameOrUniqueIdentifierOrProductTypeOrProcessor(text, data, rdxUserExtendedMe)
              }
              // currentState={{
              //   //icon: (state) => getIconByLoggingState(state),
              //   color: state => getColorByProductState(state),
              //   text: state => getTextByProductState(state)
              // }}
            />
          </View>
        ) : null}

        {openCloseProjectModal && (
          <UrstammModal
            testID={'close_project_modal'}
            text={i18n.t('views.project.are_you_sure_want_close_project')}
            textStyle={UrstammStyleModal.formBtextModalStyle}
            visible={openCloseProjectModal}
            buttonText={{
              left: i18n.t('utility_components.modal.cancel'),
              right: i18n.t('utility_components.modal.confirm')
            }}
            confirm={(confirmed: boolean) => {
              confirmed ? props.closingProject?.(props.projectSelected) : null, setOpenCloseProjectModal(false);
            }}
          />
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  imagePreview: {
    flex: 1,
    justifyContent: 'center'
  },
  cameraTopButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'flex-end'
  },
  top_button: {
    backgroundColor: ColorTheme.lightGrey,
    borderColor: ColorTheme.mainGreen,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    zIndex: 2,
    elevation: 2,
    maxWidth: 40,
    alignSelf: 'flex-end'
  },
  iconCloseContainer: {
    minWidth: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center'
  }
});
