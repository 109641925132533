import { PlatformHelper } from '@helpers/PlatformHelper';
import { i18n } from '@i18n/i18n';
import { OfflineSyncClassEnum, OfflineSyncToUpdate } from '@redux/features/offlineSync/offlineSyncSlice';
import { TrunkListItem } from '@redux/features/trunk/trunkSlice';
import {
  Batch,
  BatchCurrentStateEnum,
  BatchDTO,
  Company,
  CompanyProductType,
  DeliverySheetCurrentStateEnum,
  DeliverySheetCurrentUrstammStateEnum,
  DeliverySheetDTO,
  DeliverySheetDTOCurrentStateEnum,
  DeliverySheetDTOCurrentUrstammStateEnum,
  DeliverySheetDTORecordingTypeEnum,
  Logging,
  LoggingCurrentStateEnum,
  Packet,
  PacketCurrentStateEnum,
  PacketDTO,
  PacketSpecies,
  PacketSpeciesSpeciesEnum,
  PageCompany,
  ProcessorBatch,
  ProcessorBatchCurrentStateEnum,
  ProcessorBatchDTO,
  ProcessorShippingNote,
  ProcessorShippingNoteCurrentStateEnum,
  ProcessorShippingNoteDTO,
  Product,
  ProductCurrentStateEnum,
  ProductDTO,
  ProductSpecies,
  ProductSpeciesSpeciesEnum,
  Project,
  ProjectCurrentStateEnum,
  ProjectDTO,
  ShippingNote,
  ShippingNoteCurrentStateEnum,
  ShippingNoteDTO,
  Tree,
  TreeDTOSpeciesEnum,
  TreeRecordingTypeEnum,
  Trunk,
  TrunkCurrentStateEnum,
  UserExtended,
  UserExtendedTypeEnum
} from '@services/apis/generated';
import { Keyboard, Linking } from 'react-native';
import { getTextByTreeOrTrunkSpecies, loggingStateIsClosed } from './UrstammUtilityCurrentState';
import { changeLoaderStatus } from '@redux/features/loader/loaderSlice';
import { resetPacketList } from '@redux/features/packet/packetSlice';
import { UrstammNavigationHelper } from '../../navigation/UrstammNavigationHelper';
import { ErrorHelper } from '@helpers/ErrorHelper';
import { AlertHelper } from '@helpers/AlertHelper';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import { customCompanyProductTypeResourceApi, customCompanyResourceApi } from '@services/apis/ApiConfiguration';
import { resetCompanyProductTypeList, setCompanyProductTypeList } from '@redux/features/product-type/companyProductTypeSlice';
import moment from 'moment';

export const MOCK_COMPANY_IDI_NUMBER = '9999999999';
export const MOCK_COMPANY_FRONTEND_ID = -200;

/**
 * Checks if given Company is a mock company
 * @param company Company to check
 * @returns True if this is the mock company, false otherwise
 */
export const isCompanyMock = (company: Company | undefined) => {
  if (!company) return false;
  return company.idiNumber == MOCK_COMPANY_IDI_NUMBER || company.id == MOCK_COMPANY_FRONTEND_ID;
};

export const urstammUtilOpenMaps = (elementSelected: any) => {
  const url = 'https://maps.google.com/?q=' + elementSelected.latitude + ',' + elementSelected.longitude;
  Linking.openURL(url);
};

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const urstammUtilGoBack = (navigation): void => {
  Keyboard.dismiss();
  sleep(PlatformHelper.isIos() ? 400 : 100).then(() => {
    navigation.goBack();
  });
};

export const formatPhotoNumber = (toFormat: string, padLengthValue?: number) => {
  const padLength = padLengthValue ? padLengthValue : 3;
  return padZeroWithLength(toFormat, padLength);
};

export const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

/**
 * Checking if the logging is closed
 * @param logging
 * @returns boolean
 */
export const loggingIsClosed = (logging: Logging): boolean => {
  if (logging.currentState == LoggingCurrentStateEnum.Closed) return true;
  return false;
};

/**
 *
 * @param counter (with selected: boolean)
 * @returns
 */
export const getCounterBySelected = (list): string => {
  if (list?.length > 0) return list.filter(val => val.selected).length.toString();
  return '0';
};

/**
 * Get pad string with given length
 */
export const buildPadZeroString = (padLength: number): string => {
  const padChar = '0';
  let pad = '';
  for (let i = 0; i < padLength; i++) {
    pad += padChar;
  }
  return pad;
};

/**
 * Pad given string with length
 */
export const padZeroWithLength = (toFormat: string, padLength: number) => {
  const pad = buildPadZeroString(padLength);
  return pad.substring(0, pad.length - toFormat.length) + toFormat;
};

export const getMandatorySymbol = (mandatory: boolean) => {
  if (mandatory) return '*';
  return '';
};

export const truncate = (text: any, maxLength: number) => {
  if (text && typeof text == 'string') {
    return text.length <= maxLength ? text : text.substring(0, maxLength) + '...';
  }
  return text;
};

/**
 * It checks if an existing array contains the given element (filter by Id).
 * In case it exists it is replaced, otherwise it is added to the array
 * @param array Array of existing elements
 * @param element New element to add (if needed)
 */
export const pushElementOrReplaceById = (array: any[], element: any) => {
  const indexComplete = array.findIndex(object => object.id === element.id);
  if (indexComplete === -1) {
    array.push(element);
  } else {
    array[indexComplete] = element;
  }
};
export const pushTrunkOrReplaceById = (array: TrunkListItem[], element: TrunkListItem) => {
  const indexComplete = array.findIndex(object => object.trunk.id === element.trunk.id);
  if (indexComplete === -1) {
    array.push(element);
  } else {
    array[indexComplete] = element;
  }
};

export const getTreeTitle = (tree: Tree) => {
  if (tree.recordingType == TreeRecordingTypeEnum.Single) {
    return tree.photoNumber;
  }
  return tree.multipleCounter?.toString();
};

export const deliverySheetIsSingleRecordingType = (data: DeliverySheetDTORecordingTypeEnum) => {
  if (data == DeliverySheetDTORecordingTypeEnum.Single) return true;
  return false;
};

export const getCardTitleByRecordingType = (data: Tree): string => {
  if (data.recordingType == TreeRecordingTypeEnum.Single) {
    return data.photoNumber ? data.photoNumber : '--';
  }
  return data.multipleCounter ? data.multipleCounter.toString() : '--';
};

export const getCardTitleByBatchOrPacket = (data: Batch | Packet): string => {
  return data.name && data.uniqueIdentifier ? data.name + ' (' + data.uniqueIdentifier + ')' : '--';
};

export const getCardTitleByProcessorBatchOrProduct = (data: ProcessorBatch | Product): string => {
  return data.name && data.uniqueIdentifier ? data.name + ' (' + data.uniqueIdentifier + ')' : '--';
};

export const getCardTitleByProject = (data: Project): string => {
  return data.name && data.uniqueNumber ? data.name + ' (' + data.uniqueNumber + ')' : '--';
};

export const getCardTitleByTrunkNumberFas = (data: Trunk): string => {
  return data.trunkNumberFAS ? data.trunkNumberFAS.toString() : '--';
};

export const convertDateToActualFormat = (value: number): string => {
  const date = (value * 1000);
  const finalDate = moment(date).format('DD/MM/YYYY');
  return finalDate;
}

/**
 * Creates the card title for an offline sync object.
 * It gets the "class" property and build the string from it.
 * @param data OfflineSyncToUpdate object
 * @returns The title for card
 */
export const getCardTitleByByOfflineSync = (data: OfflineSyncToUpdate): string => {
  const offlineSyncClass: OfflineSyncClassEnum = data.class;
  switch (offlineSyncClass) {
    case OfflineSyncClassEnum.DELIVERYSHEET:
      return i18n.t('generics.delivery_sheet');
    case OfflineSyncClassEnum.DELIVERYSHEETPHOTO:
      return i18n.t('generics.delivery_sheet_photo');
    case OfflineSyncClassEnum.LOGGING:
      return i18n.t('generics.logging');
    case OfflineSyncClassEnum.OTHERASSORTMENT:
      return i18n.t('generics.other_assortment');
    case OfflineSyncClassEnum.PILE:
      return i18n.t('generics.pile');
    case OfflineSyncClassEnum.PLOT:
      return i18n.t('generics.plot');
    case OfflineSyncClassEnum.TREE:
      return i18n.t('generics.tree');
    case OfflineSyncClassEnum.TRUNK:
      return i18n.t('generics.trunk');
  }
  return '';
};

export const packetChangedProperties = (data?: Packet, userType?: UserExtendedTypeEnum) => {
  if (data?.currentState == PacketCurrentStateEnum.InProgress) return false;
  return true;
};

export const productChangedProperties = (data?: Product, userType?: UserExtendedTypeEnum) => {
  if (data?.currentState == ProductCurrentStateEnum.InProgress) return false;
  return true;
};

export const trunkChangedProperties = (data?: Trunk, userType?: UserExtendedTypeEnum) => {
  if (data?.lengthChanged || data?.qualityChanged || data?.diameterChanged || data?.speciesChanged) return false;
  return trunkIsValid(data?.currentState);
};

export const trunkInProgress = (currentState?: TrunkCurrentStateEnum) => {
  if (
    currentState == TrunkCurrentStateEnum.InProgressByForestry ||
    currentState == TrunkCurrentStateEnum.InProgressBySawmill
  ) {
    return true;
  }
  return false;
};

export const editTrunkByDsAndTrunkStatus = (trunk?: Trunk, userType?: UserExtendedTypeEnum) => {
  if (
    !dsCurrentStatusIsInProgressOrValidOrClosed(trunk?.deliverySheet?.currentState) &&
    (trunk?.currentState == TrunkCurrentStateEnum.EditedByForestry ||
      trunk?.currentState == TrunkCurrentStateEnum.InProgressBySawmill) &&
    userType == UserExtendedTypeEnum.Forestry
  ) {
    return true;
  }
  if (
    !dsCurrentStatusIsInProgressOrValidOrClosed(trunk?.deliverySheet?.currentState) &&
    (trunk?.currentState == TrunkCurrentStateEnum.InProgressByForestry ||
      trunk?.currentState == TrunkCurrentStateEnum.EditedBySawmill) &&
    userType == UserExtendedTypeEnum.Sawmill
  ) {
    return true;
  }
  return false;
};

export const dsCurrentStatusIsInProgressOrValidOrClosed = (
  currentState?: DeliverySheetCurrentStateEnum | DeliverySheetDTOCurrentStateEnum
) =>
  !!currentState &&
  dsCurrentStatusIsOneOf(
    currentState,
    DeliverySheetCurrentStateEnum.InProgressByForestry,
    DeliverySheetCurrentStateEnum.InProgressBySawmill,
    DeliverySheetCurrentStateEnum.Valid,
    DeliverySheetCurrentStateEnum.Closed
  );

export const dsCurrentStatusIsInProgressBySawmill = (
  currentState?:
    | DeliverySheetCurrentStateEnum
    | DeliverySheetDTOCurrentStateEnum
    | DeliverySheetCurrentUrstammStateEnum
    | DeliverySheetDTOCurrentUrstammStateEnum
) => dsCurrentStatusIs(DeliverySheetCurrentStateEnum.InProgressBySawmill, currentState);

export const dsCurrentStatusIsValidated = (
  currentState?: DeliverySheetCurrentUrstammStateEnum | DeliverySheetDTOCurrentUrstammStateEnum
) => dsCurrentStatusIs(DeliverySheetCurrentUrstammStateEnum.Validated, currentState);

export const dsCurrentStatusIsClosed = (
  currentState?: DeliverySheetCurrentUrstammStateEnum | DeliverySheetDTOCurrentUrstammStateEnum
) => dsCurrentStatusIs(DeliverySheetCurrentUrstammStateEnum.Closed);

export const dsCurrentStatusIsInProgress = (
  currentState?: DeliverySheetCurrentUrstammStateEnum | DeliverySheetDTOCurrentUrstammStateEnum
) => dsCurrentStatusIs(DeliverySheetCurrentUrstammStateEnum.InProgress, currentState);

export const dsCurrentStatusIsDelivere = (
  currentState?: DeliverySheetCurrentUrstammStateEnum | DeliverySheetDTOCurrentUrstammStateEnum
) => dsCurrentStatusIs(DeliverySheetCurrentUrstammStateEnum.Delivered, currentState);

export const dsCurrentStatusIsDelivered = dsCurrentStatusIsDelivere;

export const dsCurrentStatusIs = (
  state:
    | DeliverySheetCurrentStateEnum
    | DeliverySheetDTOCurrentStateEnum
    | DeliverySheetCurrentUrstammStateEnum
    | DeliverySheetDTOCurrentUrstammStateEnum,
  currentState?:
    | DeliverySheetCurrentStateEnum
    | DeliverySheetDTOCurrentStateEnum
    | DeliverySheetCurrentUrstammStateEnum
    | DeliverySheetDTOCurrentUrstammStateEnum
) => !!currentState && dsCurrentStatusIsOneOf(currentState!, state);

export const dsCurrentStatusIsOneOf = (
  currentState:
    | DeliverySheetCurrentStateEnum
    | DeliverySheetDTOCurrentStateEnum
    | DeliverySheetCurrentUrstammStateEnum
    | DeliverySheetDTOCurrentUrstammStateEnum,
  ...statuses: (
    | DeliverySheetCurrentStateEnum
    | DeliverySheetDTOCurrentStateEnum
    | DeliverySheetCurrentUrstammStateEnum
    | DeliverySheetDTOCurrentUrstammStateEnum
  )[]
) => statuses.includes(currentState);

export const trunkIsValid = (currentState?: TrunkCurrentStateEnum) => {
  if (currentState == TrunkCurrentStateEnum.Valid) {
    return true;
  }
  return false;
};

export const deliverySheetIsValid = (
  currentState?: DeliverySheetDTOCurrentStateEnum | DeliverySheetCurrentStateEnum
) => {
  if (
    currentState == DeliverySheetDTOCurrentStateEnum.Valid ||
    currentState == DeliverySheetDTOCurrentStateEnum.Closed
  ) {
    return true;
  }
  return false;
};

export const deliverySheetIsInProgress = (
  currentState?: DeliverySheetDTOCurrentStateEnum | DeliverySheetCurrentStateEnum
) => {
  if (
    currentState == DeliverySheetDTOCurrentStateEnum.InProgressByForestry ||
    currentState == DeliverySheetDTOCurrentStateEnum.InProgressBySawmill
  ) {
    return true;
  }
  return false;
};

/**
 * Checking DS currentState (initial phase)
 * @param dsCurrentState
 * @returns
 */
export const canEditByDSCurrentState = (
  dsCurrentState: DeliverySheetDTOCurrentUrstammStateEnum,
  loggingCurrentState: LoggingCurrentStateEnum
) => {
  if (!loggingStateIsClosed(loggingCurrentState!) && dsCurrentStatusIsInProgress(dsCurrentState)) {
    return true;
  }
  return false;
};

/**
 * Checking DS currentState (initial phase)
 * @param dsCurrentState
 * @returns
 */
export const canEditByDSCurrentStateDelivered = (
  dsCurrentState: DeliverySheetDTOCurrentUrstammStateEnum,
  loggingCurrentState: LoggingCurrentStateEnum
) => {
  if (!loggingStateIsClosed(loggingCurrentState!) && dsCurrentStatusIsDelivere(dsCurrentState)) {
    return true;
  }
  return false;
};

export const canEditDSRecordingType = (deliverySheet: DeliverySheetDTO) => {
  return (
    canEditByDSCurrentState(deliverySheet.currentUrstammState!, deliverySheet.logging?.currentState!) &&
    (!deliverySheet.recordingType || deliverySheet.trunks?.length == 0)
  );
};

/**
 * Checking DS currentState (initial phase)
 * @param dsCurrentState
 * @returns
 */
export const canAddTrunksByDSCurrentState = (
  dsCurrentState: DeliverySheetDTOCurrentUrstammStateEnum,
  loggingCurrentState: LoggingCurrentStateEnum,
  userType: UserExtendedTypeEnum,
  recordingType: DeliverySheetDTORecordingTypeEnum
) => {
  if (
    !loggingStateIsClosed(loggingCurrentState!) &&
    dsCurrentStatusIsInProgress(dsCurrentState) &&
    userType == UserExtendedTypeEnum.Forestry &&
    recordingType
  ) {
    return true;
  }
  return false;
};

/**
 * Checking if the user logged is a sawmill
 * @param loggedUser
 * @returns
 */
export const loggedUserIsASawmill = (loggedUser: UserExtended) => {
  if (loggedUser.type == UserExtendedTypeEnum.Sawmill) return true;

  return false;
};

/**
 * Checking if the user logged is a processor
 * @param loggedUser
 * @returns
 */
export const loggedUserIsAProcessor = (loggedUser: UserExtended) => {
  if (loggedUser.type == UserExtendedTypeEnum.Processor) return true;

  return false;
};

/**
 * Checking if the user logged is a project manager
 * @param loggedUser
 * @returns
 */
export const loggedUserIsAProjectmanager = (loggedUser: UserExtended) => {
  if (loggedUser.type == UserExtendedTypeEnum.Projectmanager) return true;

  return false;
};

/**
 * Checks if the given ProcessorShippingNote has a reference ShippingNote.
 * In this case it returns the ShippingNote, otherwise it gets the id of ProcessorShippingNote itself.
 *
 * @param processorShippingNote ProcessorShippingNote to read
 * @returns The id of reference ShippingNote if present, the object id otherwise
 */
export const idFromProcessorShippingNote = (
  processorShippingNote?: ProcessorShippingNote | ProcessorShippingNoteDTO
) => {
  if (!processorShippingNote) return '';

  return processorShippingNote.referenceShippingNote
    ? processorShippingNote.referenceShippingNote.id
    : processorShippingNote.id;
};

/**
 * Returns a lit of tree/trunk species adding first the main species, then the others are sorted by
 * translated name.
 * Finally it adds mixed and other species.
 *
 * @returns The list of sorted tree species
 */
export const getTreeDTOSpeciesList = (): string[] => {
  let alphabeticList = Object.values(TreeDTOSpeciesEnum)
    .filter(
      species =>
        species != TreeDTOSpeciesEnum.Spruce &&
        species != TreeDTOSpeciesEnum.Fir &&
        species != TreeDTOSpeciesEnum.Beech &&
        species != TreeDTOSpeciesEnum.MixedHardwood &&
        species != TreeDTOSpeciesEnum.MixedSoftwood &&
        species != TreeDTOSpeciesEnum.OtherHardWood &&
        species != TreeDTOSpeciesEnum.OtherSoftWood
    )
    .sort((a, b) => (getTextByTreeOrTrunkSpecies(a) < getTextByTreeOrTrunkSpecies(b) ? -1 : 0));
  const mainSpecies: TreeDTOSpeciesEnum[] = [
    TreeDTOSpeciesEnum.Spruce,
    TreeDTOSpeciesEnum.Fir,
    TreeDTOSpeciesEnum.Beech
  ];
  const lastSpecies = [
    TreeDTOSpeciesEnum.MixedSoftwood,
    TreeDTOSpeciesEnum.MixedHardwood,
    TreeDTOSpeciesEnum.OtherSoftWood,
    TreeDTOSpeciesEnum.OtherHardWood
  ];
  return mainSpecies.concat(alphabeticList).concat(lastSpecies);
};

/**
 * Returns a lit of packet species adding first the main species, then the others are sorted by
 * translated name.
 * Finally it adds mixed and other species.
 *
 * @returns The list of sorted tree species
 */
export const getPacketSpeciesList = (): string[] => {
  let alphabeticList = Object.values(PacketSpeciesSpeciesEnum)
    .filter(
      species =>
        species != PacketSpeciesSpeciesEnum.Spruce &&
        species != PacketSpeciesSpeciesEnum.Fir &&
        species != PacketSpeciesSpeciesEnum.Beech &&
        species != PacketSpeciesSpeciesEnum.MixedHardwood &&
        species != PacketSpeciesSpeciesEnum.MixedSoftwood &&
        species != PacketSpeciesSpeciesEnum.OtherHardWood &&
        species != PacketSpeciesSpeciesEnum.OtherSoftWood
    )
    .sort((a, b) => (getTextByTreeOrTrunkSpecies(a) < getTextByTreeOrTrunkSpecies(b) ? -1 : 0));
  const mainSpecies: PacketSpeciesSpeciesEnum[] = [
    PacketSpeciesSpeciesEnum.Spruce,
    PacketSpeciesSpeciesEnum.Fir,
    PacketSpeciesSpeciesEnum.Beech
  ];
  const lastSpecies = [
    PacketSpeciesSpeciesEnum.MixedSoftwood,
    PacketSpeciesSpeciesEnum.MixedHardwood,
    PacketSpeciesSpeciesEnum.OtherSoftWood,
    PacketSpeciesSpeciesEnum.OtherHardWood
  ];
  return mainSpecies.concat(alphabeticList).concat(lastSpecies);
};

/**
 * Returns a lit of product species adding first the main species, then the others are sorted by
 * translated name.
 * Finally it adds mixed and other species.
 *
 * @returns The list of sorted tree species
 */
export const getProductSpeciesList = (): string[] => {
  let alphabeticList = Object.values(ProductSpeciesSpeciesEnum)
    .filter(
      species =>
        species != ProductSpeciesSpeciesEnum.Spruce &&
        species != ProductSpeciesSpeciesEnum.Fir &&
        species != ProductSpeciesSpeciesEnum.Beech &&
        species != ProductSpeciesSpeciesEnum.MixedHardwood &&
        species != ProductSpeciesSpeciesEnum.MixedSoftwood &&
        species != ProductSpeciesSpeciesEnum.OtherHardWood &&
        species != ProductSpeciesSpeciesEnum.OtherSoftWood
    )
    .sort((a, b) => (getTextByTreeOrTrunkSpecies(a) < getTextByTreeOrTrunkSpecies(b) ? -1 : 0));
  const mainSpecies: ProductSpeciesSpeciesEnum[] = [
    ProductSpeciesSpeciesEnum.Spruce,
    ProductSpeciesSpeciesEnum.Fir,
    ProductSpeciesSpeciesEnum.Beech
  ];
  const lastSpecies = [
    ProductSpeciesSpeciesEnum.MixedSoftwood,
    ProductSpeciesSpeciesEnum.MixedHardwood,
    ProductSpeciesSpeciesEnum.OtherSoftWood,
    ProductSpeciesSpeciesEnum.OtherHardWood
  ];
  return mainSpecies.concat(alphabeticList).concat(lastSpecies);
};

/**
 * Batch
 * @param deliverySheetCustom
 * @returns
 */
export const checkIfAtLeastOneDSIsSelected = (deliverySheetCustom: any[]): boolean => {
  let atLeastOne = false;
  if (deliverySheetCustom && deliverySheetCustom.length > 0)
    deliverySheetCustom.forEach(ds => {
      if (ds.selected) return (atLeastOne = true);
    });
  return atLeastOne;
};

export const checkIfAtLeastOneProcessorShippingNoteIsSelected = (processorShippingNoteCustom: any[]): boolean => {
  let atLeastOne = false;
  if (processorShippingNoteCustom && processorShippingNoteCustom.length > 0)
    processorShippingNoteCustom.forEach(psn => {
      if (psn.selected) return (atLeastOne = true);
    });
  return atLeastOne;
};

export const checkIfAtLeastOneProductIsSelected = (products: any[]): boolean => {
  return products?.filter(p => !!p.selected || !!p.active).length > 0;
};

export const generateSet = (list: any) => {
  let listId: any = [];
  list.forEach(val => {
    if (val.selected) {
      listId.push({ id: val.id });
    }
  });
  return listId;
};

export const generateSetForCertification = (list: any) => {
  let listId: any = [];
  list.forEach(val => {
    if (val.selected) {
      listId.push({ certification: val.id });
    }
  });
  return listId;
};

export const generateSetForPacketSpecies = (
  list: { selected?: boolean; active: boolean; id: string }[]
): Set<PacketSpecies> => {
  return new Set(
    list.filter(l => !!l.selected || !!l.active).map(l => ({ species: l.id as PacketSpeciesSpeciesEnum }))
  );
};

export const packetIsClosed = (packet: Packet | PacketDTO) => {
  if (packet.currentState == PacketCurrentStateEnum.Closed) return true;
  return false;
};

export const packetIsSentInShippingNote = (packet: Packet | PacketDTO) => {
  if (packet.currentState == PacketCurrentStateEnum.SentInShippingNote) return true;
  return false;
};

export const packetIsSelectedForShippingNote = (packet: Packet | PacketDTO) => {
  if (packet.currentState == PacketCurrentStateEnum.SelectedForShippingNote) return true;
  return false;
};

export const packetIsEditable = (packet: Packet | PacketDTO) =>
  !packetIsClosed(packet) && !packetIsSentInShippingNote(packet) && !packetIsSelectedForShippingNote(packet);

export const allPacketAreClosed = (batchDTO: BatchDTO) => {
  let packets = batchDTO.packets ? [...batchDTO.packets] : [];
  return packets?.every(packet => packet.currentState == PacketCurrentStateEnum.Closed);
};

export const allPacketAreClosedOrSentInShippingNote = (batchDTO: BatchDTO) => {
  let packets = batchDTO.packets ? [...batchDTO.packets] : [];
  return packets?.every(packet => packetIsClosed(packet) || packetIsSentInShippingNote(packet));
};

export const generateSetForProductSpecies = (
  list: { selected?: boolean; active: boolean; id: string }[]
): Set<ProductSpecies> => {
  return new Set(
    list.filter(l => !!l.selected || !!l.active).map(l => ({ species: l.id as ProductSpeciesSpeciesEnum }))
  );
};

export const projectIsClosed = (project: Project | ProjectDTO) => {
  if (project.currentState == ProjectCurrentStateEnum.Closed) return true;
  return false;
};

export const projectIsEditable = (project: Project | ProjectDTO) => {
  return !projectIsClosed(project);
};

export const productIsClosed = (product: Product | ProductDTO) => {
  if (product.currentState == ProductCurrentStateEnum.Closed) return true;
  return false;
};

export const productIsSentInShippingNote = (product: Product | ProductDTO) => {
  if (product.currentState == ProductCurrentStateEnum.SentInShippingNote) return true;
  return false;
};

export const productIsEditable = (product: Product | ProductDTO) => {
  return !productIsClosed(product) && !productIsSentInShippingNote(product);
};

export const allProductAreClosed = (processorBatchDTO: ProcessorBatchDTO) => {
  let products = processorBatchDTO.products ? [...processorBatchDTO.products] : [];
  return products?.every(product => product.currentState == ProductCurrentStateEnum.Closed);
};

export const allProductAreClosedOrSentInShippingNote = (processorBatchDTO: ProcessorBatchDTO) => {
  let products = processorBatchDTO.products ? [...processorBatchDTO.products] : [];
  return products?.every(product => productIsClosed(product) || productIsSentInShippingNote(product));
};

export const batchIsClosed = (batch: Batch | BatchDTO) => {
  if (batch && batch.currentState == BatchCurrentStateEnum.Closed) return true;
  return false;
};

export const processorBatchIsClosed = (processorBatch: ProcessorBatch | ProcessorBatchDTO) => {
  if (processorBatch && processorBatch.currentState == ProcessorBatchCurrentStateEnum.Closed) return true;
  return false;
};

export const shippingNoteIsClosed = (shippingNote: ShippingNote | ShippingNoteDTO) => {
  return shippingNote && shippingNote.currentState != ShippingNoteCurrentStateEnum.InProgress;
};

export const processorShippingNoteIsClosed = (
  processorShippingNote: ProcessorShippingNote | ProcessorShippingNoteDTO
) => {
  return (
    processorShippingNote && processorShippingNote.currentState != ProcessorShippingNoteCurrentStateEnum.InProgress
  );
};

export const getSpeciesListId = (dataSelected, setState: any, state) => {
  let listId: any = [];
  dataSelected.forEach(val => listId.push({ species: val.id }));
  setState(listId);
};

export const sortOfflineList = (list: any, requestParameters: any) => {
  // In case the sorting is defined in the sort array (e.g. logging)
  if (requestParameters.sort && requestParameters.sort[0]) {
    requestParameters.sortBy = requestParameters.sort[0].split(',')[0];
    requestParameters.direction = requestParameters.sort[0].split(',')[1];
  }

  if (requestParameters.sortBy == null || undefined) requestParameters.sortBy = 'creationDate';
  if (requestParameters.direction == null || undefined) requestParameters.direction = 'DESC';

  if (requestParameters.sortBy == 'name') {
    return list.sort((a: any, b: any) => {
      if (requestParameters.direction == 'ASC') {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
      } else if (requestParameters.direction == 'DESC') {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
      }
    });
  } else if (requestParameters.sortBy == 'creationDate') {
    return list.sort((a: any, b: any) => {
      if (requestParameters.direction == 'ASC') {
        return Date.parse(a.creationDate) - Date.parse(b.creationDate);
      } else if (requestParameters.direction == 'DESC') {
        return Date.parse(b.creationDate) - Date.parse(a.creationDate);
      }
    });
  }
};

export const handlePacketCreationSuccess = async (dispatch, navigation, batchSelected, packet) => {
  dispatch(changeLoaderStatus(false));
  if (packet) {
    dispatch(resetPacketList());
    Keyboard.dismiss();
    await sleep(PlatformHelper.isIos() ? 300 : 100);
    UrstammNavigationHelper.navigateToBatchDetails(navigation, batchSelected, true);
  }
};

export const handlePacketCreationError = async (dispatch, error) => {
  dispatch(changeLoaderStatus(false));
  let errorMessage = await ErrorHelper.getServerMessageFromJsonOrResponse(error);
  let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
  AlertHelper.showSimpleAlert('Error', errorJson);
};

export const getAllCompanyProcessorConnectedToMe = (dispatch, setProcessorList, order?: ButtonSelect[]) => {
  let sortBy = order && order.length > 0 ? [order[0].sort?.sortBy + ',' + order[0].sort?.direction] : ['name,desc'];
  dispatch(changeLoaderStatus(true));

  customCompanyResourceApi
    .getCompaniesConnectedToMe({
      sort: sortBy,
      customCompanyCriteria: {},
      page: 0,
      size: 1000
    })
    .then((list: PageCompany) => {
      dispatch(changeLoaderStatus(false));

      if (list?.content?.length) {
        list.content.push({ name: i18n.t('views.packet.no_urstamm_processor'), id: -200 });
        setProcessorList(list.content);
      }
    })
    .catch(async error => {
      dispatch(changeLoaderStatus(false));
      let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
      let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
      AlertHelper.showSimpleAlert('Error', errorJson);
    });
};

export const getCompanyProductType = (dispatch) => {
  dispatch(changeLoaderStatus(true));

  customCompanyProductTypeResourceApi
    .getAllCompanyProductTypesForCompany({})
    .then((productTypeList: CompanyProductType[]) => {
      dispatch(changeLoaderStatus(false));

      if (productTypeList) {
        dispatch(resetCompanyProductTypeList());
        dispatch(setCompanyProductTypeList(productTypeList));
      }
    })
    .catch(async error => {
      dispatch(changeLoaderStatus(false));
      let errorMessage = await ErrorHelper.getServerMessageFromJson(error);
      let errorJson = await ErrorHelper.getDetailFromServerMessage(errorMessage);
      AlertHelper.showSimpleAlert('Error', errorJson);
    });
};