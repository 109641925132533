import UrstammButtonBase from '@components/utility-components/button/UrstammButtonBase';
import { ButtonSelect } from '@components/utility-components/button/UrstammButtonSelect';
import UrstammDatePicker from '@components/utility-components/date-picker/UrstammDatePicker';
import UrstammIcon from '@components/utility-components/icon/UrstammIcon';
import UrstammInput from '@components/utility-components/input/UrstammInput';
import UrstammList, { ListSubData } from '@components/utility-components/list/UrstammList';
import UrstammModal from '@components/utility-components/modal/UrstammModal';
import UrstammModalSelect from '@components/utility-components/modal/UrstammModalSelect';
import { AlertHelper } from '@helpers/AlertHelper';
import { NumberHelper } from '@helpers/NumberHelper';
import { i18n } from '@i18n/i18n';
//import { setPacketList } from '@redux/features/packet/packetSlice';
import { RootState } from '@redux/store';
import {
  Batch,
  Company,
  Packet,
  PacketDTO,
  ShippingNote,
  ShippingNoteDTO,
  ShippingNoteDTOCurrentStateEnum,
  UserExtended
} from '@services/apis/generated';
import { ExternalCompany } from '@services/apis/generated/models/ExternalCompany';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Linking, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import { useSelector } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {
  getColorByBatchState,
  getColorByPacketState,
  getColorByShippingNoteState,
  getIconByBatchState,
  getIconByShippingNoteState,
  getTextByBatchState,
  getTextByBatchType,
  getTextByPacketState,
  getTextByShippingNoteState
} from '../../../utils/classes/UrstammUtilityCurrentState';
import {
  filterPacketByNameOrUniqueIdentifierOrProductTypeOrProcessor
} from '../../../utils/classes/UrstammUtilityFilter';
import {
  urstammUtilDisplayError,
  urstammUtilUpdateForm,
  urstammUtilValidator,
  urstammUtilValidatorInError
} from '../../../utils/classes/UrstammUtilityForm';
import {
  convertDateToActualFormat,
  getCardTitleByBatchOrPacket,
  getCounterBySelected,
  getMandatorySymbol,
  isCompanyMock,
  loggedUserIsASawmill,
  shippingNoteIsClosed
} from '../../../utils/classes/UrstammUtilityFunctions';
import { buildShippingNotePdfDownloadUrl } from '../../../utils/classes/UrstammUtilityImage';
import { ColorTheme } from '../../../utils/styles/ColorTheme';
import { lightGreenBorderWhiteBGButton, mainGreenButton } from '../../../utils/styles/UrstammButtonModelStyle';
import {
  UrstammStyle,
  UrstammStyleButton,
  UrstammStyleCollapsible,
  UrstammStyleContainer,
  UrstammStyleLayout,
  UrstammStyleList,
  UrstammStyleModal
} from '../../../utils/styles/UrstammStyle';
import PacketListView from '../packet/PacketListView';
import {
  CertificationProps,
  CompanyProductTypeProps,
  CompanySurfaceProps,
  TrunkProps
} from '../packet/PacketRegistrationView';
import { ShippingNoteForm } from './ShippingNoteRegistrationView';

interface BatchProps {
  batchList: any[];
  batchSelected: (data: any) => void;
  resetBatchSelected?: () => void;
  batchSelectedID: (batch: Batch) => void;
  moreItems: () => void;
}
interface ProcessorProps {
  getAll: Function;
  processorList: any[];
  processorSelectedID: (processor: Company) => void;
}

interface PacketListProps {
  packetSelected: (packet?: Packet | PacketDTO) => void;
}

export default function ShippingNoteDetailsView(props: {
  navigation: any;
  shippingNoteSelectedDTO: ShippingNoteDTO;
  customPacketList: any; // (selected: boolean)
  moreItems: () => void;
  userExtendedMe?: UserExtended;
  packetSelected: (data: any) => void;
  updateShippingNote: (shippingNoteDTO: ShippingNoteDTO) => void;
  resetPacketSelected?: () => void;
  confirmPacketSelected?: () => void;
  shippingNoteList: ShippingNote[];
  batchProps: BatchProps;
  processorProps: ProcessorProps;
  packetListProps?: PacketListProps;
  //applyOrder: (orderSelected: ButtonSelect[]) => void;
  showSortBy: boolean;
  closeShowSortBy: () => void;
  batchList: Batch[];
  processorList: Company[];
  certificationProps?: CertificationProps;
  trunkProps?: TrunkProps;
  closingShippingNote: (shippingNoteDTO: ShippingNoteDTO, externalCompany?: ExternalCompany) => void;
}) {
  const rdxUserExtendedMe = useSelector((state: RootState) => state.persistedReducer.user.extendedMe);

  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [shippingNoteSelected, setShippingNoteSelected] = useState<ShippingNoteDTO>();
  const [externalCompany, setExternalCompany] = useState<ExternalCompany>({});
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [openCloseShippingNoteModal, setOpenCloseShippingNoteModal] = useState<boolean>(false);
  const [openClosingShippingNoteWithExternalCompanyModal, setOpenClosingShippingNoteWithExternalCompanyModal] =
    useState<boolean>(false);
  const [packetList, setPacketList] = useStateWithCallbackLazy<any>(() => {});
  const [showProcessorModalSelect, setShowProcessorModalSelect] = useState<boolean>(false);
  const [showPacketSelect, setShowPacketSelect] = useState<boolean>(false);
  const [showBatchModalSelect, setShowBatchModalSelect] = useState<boolean>(false);

  const [shippingNoteForm, setShippingNoteForm] = useStateWithCallbackLazy<ShippingNoteForm>({
    shippingNote: {
      name: '',
      creationDate: new Date(),
      currentState: ShippingNoteDTOCurrentStateEnum.InProgress,
      comment: ''
    }
  });

  let dsKeys: ListSubData[] = [
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      // dataContainerViewStyle: { height: 24, flexBasis: '50%' }
    },
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('views.batch.sum_volume_packets') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      // dataContainerViewStyle: { height: 24 }
    },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    }
  ];

  let batchKeys: ListSubData[] = [
    {
      title: undefined,
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'currentState',
      keyId: '1currentState',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    },
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      dataViewStyle: { justifyContent: 'flex-end', alignItems: 'center' }
    }
  ];

  let pkKeys: ListSubData[] = [
    
    {
      title: i18n.t('utility_components.list.creation'),
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'creationDate',
      keyId: '1creationDate',
      // dataContainerViewStyle: { height: 24 }
    },
    {
      title: i18n.t('generics.productType') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'productType',
      secondKey: 'name',
      keyId: '1productType',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' }
    },
    {
      title: i18n.t('views.batch.sum_volume_packets') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'sumCubage',
      keyId: '1sumCubage',
      // dataContainerViewStyle: { height: 24 }
    },
    {
      title: i18n.t('generics.processor') + ':',
      titleTextStyle: UrstammStyleList.cardTitleTextStyle,
      key: 'processor',
      secondKey: 'name',
      keyId: '1processor',
      dataViewStyle: { justifyContent: 'flex-start', alignItems: 'center' },
      truncateMaxLength: 16,
      isEnum: true
    }
  ];

  useEffect(() => {
    if (props.shippingNoteSelectedDTO) {
      setShippingNoteSelected(props.shippingNoteSelectedDTO);
      setShippingNoteForm(
        {
          shippingNote: {
            name: props.shippingNoteSelectedDTO.name,
            creationDate: props.shippingNoteSelectedDTO.creationDate,
            comment: props.shippingNoteSelectedDTO.comment,
            batch: props.shippingNoteSelectedDTO?.batch,
            processor: props.shippingNoteSelectedDTO?.processor,
            packets: props.shippingNoteSelectedDTO?.packets
          }
        },
        updatedShippingNoteForm => {
          saveButtonDisabled(errors, updatedShippingNoteForm);
        }
      );
    }
  }, [props.shippingNoteSelectedDTO]);

  useEffect(() => {
    if (props.customPacketList && props.customPacketList.length > 0)
      setPacketList(props.customPacketList, updatedPacketList => {
        saveButtonDisabled(errors, updatedPacketList);
      });
  }, [props.customPacketList]);

  useEffect(() => {
    if (shippingNoteForm.shippingNote.batch) {
    }
  }, [shippingNoteForm.shippingNote.batch]);

  /**
   *
   * @param key
   * @param value
   */
  const updateShippingNoteForm = (key: string, value: any, mustValidate?: boolean): void => {
    let form = urstammUtilUpdateForm(key, value, shippingNoteForm, 'shippingNote');
    setShippingNoteForm(
      { shippingNote: form['shippingNote'] },
      mustValidate ? updatedShippingNoteForm => validate(value, key, updatedShippingNoteForm) : () => {}
    );
  };

  //Validators
  const validators = useState<any>({
    name: { required: true }
  });

  const [errors, setErrors] = useStateWithCallbackLazy<any>({
    name: []
  });

  const validate = (value: string, key: string, updatedTreeForm: ShippingNoteForm) => {
    let errorList = urstammUtilValidator(value, key, validators);
    setErrors(
      (state: any) => ({ ...state, [key]: errorList }),
      errorsUpdated => saveButtonDisabled(errorsUpdated, updatedTreeForm)
    );
  };

  const saveButtonDisabled = (errorsUpdated, updatedPacketList: any) => {
    let disabled: boolean = false;
    if (urstammUtilValidatorInError(errorsUpdated)) {
      disabled = true;
    }
    return setSaveDisabled(disabled);
  };

  const downloadPdf = async () => {
    let documentUrl = await buildShippingNotePdfDownloadUrl(props.shippingNoteSelectedDTO);
    if (documentUrl) {
      Linking.canOpenURL(documentUrl).then(supported => {
        if (supported) {
          Linking.openURL(documentUrl);
        } else {
          AlertHelper.showSimpleErrorAlert();
        }
      });
    }
  };

  const updateProcessor = (processor: Company) => {
    // if (processor.id == -200) {
    //   setShowProcessorModalSelect(false);
    //   return setTimeout(() => setShowNoUrstammProcessor(true), 300);
    // }
    //shippingNoteForm.shippingNote.processorCustomName = '';
    updateShippingNoteForm('processor', processor);
    props.processorProps.processorSelectedID(processor);
    return setShowProcessorModalSelect(false);
  };

  const updateBatch = (batch: Batch) => {
    updateShippingNoteForm('batch', batch);
    props.batchProps.batchSelectedID(batch);
    return setShowBatchModalSelect(false);
  };

  const getShippingNoteProcessorName = (shippingNote: ShippingNote | ShippingNoteDTO): string | undefined => {
    if (!isCompanyMock(shippingNote?.processor))
      return shippingNote?.processor?.name!;
    else if (isCompanyMock(shippingNote?.processor))
      return i18n.t('views.shipping_note.no_urstamm_processor');
    return undefined;
  };

  const getProcessorName = (): string => {
    const name = getShippingNoteProcessorName(shippingNoteForm.shippingNote);
    if (name) return name
    else return i18n.t('views.shipping_note.shipping_note_registration.choose_processor');
  };

  return (
    <View style={UrstammStyle.flex1}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        contentContainerStyle={[{ flexGrow: 1, flexDirection: 'column', marginBottom: 28 }]}>
        {/**Status and creation date */}
        <View style={UrstammStyleLayout.formElementContainer}>
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {shippingNoteSelected?.currentState ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.paddingBottom4]}>
                {shippingNoteSelected?.currentState &&
                  getIconByShippingNoteState(shippingNoteSelected?.currentState as any)}
                <Text
                  style={[
                    UrstammStyleContainer.statusCreationDateTextWithIcon,
                    getColorByShippingNoteState(shippingNoteSelected.currentState)
                  ]}>
                  {getTextByShippingNoteState(shippingNoteSelected.currentState!)}
                </Text>
              </View>
            ) : null}
            {shippingNoteSelected?.creationDate ? (
              <View style={UrstammStyle.flexRow}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.creationDate') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {convertDateToActualFormat(Number(shippingNoteSelected.creationDate))}
                </Text>
              </View>
            ) : null}
          </View>
          <View style={[UrstammStyleLayout.formElementContainer]}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {shippingNoteSelected?.id ? (
                <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.id') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>{String(shippingNoteSelected?.id)}</Text>
                </View>
              ) : null}
              {/* {shippingNoteSelected?.batch ? (
                <View style={[UrstammStyle.flexRow, UrstammStyle.flexEndX, { paddingBottom: 10 }]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.batch') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>{getCardTitleByBatchOrPacket(shippingNoteSelected?.batch)}</Text>
                </View>
              ) : null} */}
            </View>
          </View>

          <View style={[UrstammStyleLayout.formElementContainer]}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {/* {shippingNoteSelected?.id ? (
                <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.id') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>{String(shippingNoteSelected?.id)}</Text>
                </View>
              ) : null} */}
              {shippingNoteSelected?.batch ? (
                <View style={[UrstammStyle.flexRow, UrstammStyle.wrap, { paddingBottom: 10 }]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.batch') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>{getCardTitleByBatchOrPacket(shippingNoteSelected?.batch)}</Text>
                </View>
              ) : null}
            </View>
          </View>

          {/** Sawmill and Processo - as sender -> receiver */}
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
            {shippingNoteSelected?.batch ? (
              <View style={[UrstammStyle.flexRow, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.recording_type') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>{getTextByBatchType(shippingNoteSelected?.batch?.recordingType)}</Text>
              </View>
            ) : null}
          </View>

          {/** Sawmill and Processo - as sender -> receiver */}
          <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap, shippingNoteSelected?.sawmill ? {} : UrstammStyle.flexEndX]}>
            {shippingNoteSelected?.sawmill ? (
              <View style={[UrstammStyle.flexRow, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.sawmill') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>{shippingNoteSelected?.sawmill.name}</Text>
              </View>
            ) : null}
            {shippingNoteSelected?.processor ? (
              <View style={[UrstammStyle.flexRow, UrstammStyle.wrap, { paddingBottom: 10 }]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.processor') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>
                  {!isCompanyMock(shippingNoteSelected?.processor)
                    ? shippingNoteSelected?.processor.name
                    : i18n.t('views.shipping_note.no_urstamm_processor')}
                </Text>
              </View>
            ) : null}
          </View>

          <View style={[UrstammStyleLayout.formElementContainer]}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {shippingNoteSelected?.packets ? (
                <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.amount_of_packet') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>{shippingNoteSelected?.packets.length}</Text>
                </View>
              ) : null}

              {/* <View style={[UrstammStyle.flex1, UrstammStyle.flexRow, UrstammStyle.flexEndX]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('views.batch.sum_volume_packets') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>{NumberHelper.roundWithThreeDecimals(shippingNoteSelected?.sumCubage)}</Text>
              </View> */}
            </View>
          </View>

          <View style={[UrstammStyleLayout.formElementContainer]}>
            <View style={[UrstammStyleContainer.row1SpaceBetween, UrstammStyle.wrap]}>
              {/* {shippingNoteSelected?.packets ? (
                <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                  <Text style={UrstammStyle.titleStyle}>{i18n.t('generics.amount_of_packet') + ': '}</Text>
                  <Text style={UrstammStyle.dataStyle}>{shippingNoteSelected?.packets.length}</Text>
                </View>
              ) : null} */}

              <View style={[UrstammStyle.flex1, UrstammStyle.flexRow]}>
                <Text style={UrstammStyle.titleStyle}>{i18n.t('views.batch.sum_volume_packets') + ': '}</Text>
                <Text style={UrstammStyle.dataStyle}>{NumberHelper.roundWithThreeDecimals(shippingNoteSelected?.sumCubage)}</Text>
              </View>
            </View>
          </View>

        </View>

        <View style={UrstammStyle.divider} />

        {/* TODO: Only for sawmill */}
        {props.shippingNoteSelectedDTO &&
        props.shippingNoteSelectedDTO.currentState == ShippingNoteDTOCurrentStateEnum.InProgress &&
        loggedUserIsASawmill(rdxUserExtendedMe) ? (
          <View style={[{ marginTop: 8 }]}>
            <UrstammButtonBase
              testID={'send_shipping_note'}
              text={i18n.t('views.shipping_note.send_shipping_note')}
              style={mainGreenButton}
              onSubmit={() =>
                props.shippingNoteSelectedDTO.processor != null
                  ? setOpenCloseShippingNoteModal(true)
                  : AlertHelper.showSimpleAlert('Error', 'cannot_send_shipping_note_without_processor')
              }
            />
          </View>
        ) : null}

        {/** Download pdf Shipping note */}
        {props.shippingNoteSelectedDTO &&
        props.shippingNoteSelectedDTO?.currentState != ShippingNoteDTOCurrentStateEnum.InProgress &&
        loggedUserIsASawmill(rdxUserExtendedMe) ? (
          <View style={{ marginTop: 8 }}>
            <UrstammButtonBase
              testID={'download_shipping_note_pdf'}
              text={i18n.t('generics.download_pdf')}
              style={mainGreenButton}
              onSubmit={downloadPdf}
            />
          </View>
        ) : null}

        {/**Edit Packet Collapsible */}
        <View style={{ marginTop: 8 }}>
          <View
            style={[
              UrstammStyleButton.smallBaseButtonStructure,
              { backgroundColor: ColorTheme.yellow },
              UrstammStyle.baseShadow
            ]}>
            <View style={UrstammStyleCollapsible.selectMainContainer}>
              <TouchableOpacity
                style={UrstammStyleCollapsible.selectCollapsible}
                onPress={() => setCollapsed(!collapsed)}>
                <Text style={UrstammStyleButton.baseButtonBWStyle}>{i18n.t('generics.edit')}</Text>

                <UrstammIcon name={collapsed ? 'angle-down' : 'angle-up'} size={24} color={'white'} />
              </TouchableOpacity>
            </View>
          </View>

          <View style={[{ marginTop: 4 }]}>
            <Collapsible
              duration={500}
              easing={'easeInBounce'}
              collapsed={collapsed}
              style={UrstammStyleCollapsible.containerCollapsible}>
              <ScrollView
                keyboardShouldPersistTaps="always"
                contentContainerStyle={[
                  {
                    flexGrow: 1,
                    flexDirection: 'column',
                    paddingBottom: 40
                  }
                ]}>
                {/** ShippingNote name */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1, { justifyContent: 'flex-start' }]}>
                  <UrstammInput
                    testID="shipping_note_name"
                    style={{
                      containerViewStyle: UrstammStyleContainer.inputContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    value={shippingNoteForm.shippingNote.name ? shippingNoteForm.shippingNote.name : ''}
                    onChangeText={(text: string) => {
                      updateShippingNoteForm('name', text, true);
                    }}
                    placeholder={i18n.t(
                      'views.shipping_note.shipping_note_registration.insert_shipping_note_name_here'
                    )}
                    textInputTitle={i18n.t('views.shipping_note.shipping_note_registration.shipping_note_name')}
                    mandatory={true}
                    editable={!shippingNoteIsClosed(props.shippingNoteSelectedDTO)}
                  />

                  {/**Validator */}
                  {errors['name'] && errors['name'].length > 0
                    ? errors['name'].map((error, idx) => {
                        return urstammUtilDisplayError(error, idx);
                      })
                    : null}
                </View>

                {/** ShippingNote creation date (preset: today) */}
                <View style={UrstammStyleLayout.formElementContainerGrw1}>
                  <UrstammDatePicker
                    testID={'shipping_note_date'}
                    containerStyle={UrstammStyleContainer.inputContainer}
                    fontStyle={UrstammStyle.formBaseLDarkGrey}
                    textInputTitle={i18n.t('generics.date')}
                    onChange={(dateTime: string) => updateShippingNoteForm('creationDate', dateTime)}
                    icon={{
                      showIcon: true,
                      iconName: 'calendar',
                      iconColor: ColorTheme.lightGreen
                    }}
                    defaultValue={shippingNoteSelected?.creationDate}
                    placeholder={i18n.t(
                      'views.shipping_note.shipping_note_registration.insert_shipping_note_date_here'
                    )}
                    mandatory={true}
                    mode={'date'}
                    disabled={shippingNoteIsClosed(props.shippingNoteSelectedDTO)}
                  />
                </View>

                {/** Batch list */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  {/**Title */}
                  <View style={UrstammStyle.textTitleContainerFlex}>
                    <Text style={UrstammStyle.textTitle}>{i18n.t('generics.batch')}</Text>
                  </View>

                  <UrstammButtonBase
                    testID={'choose_product_type'}
                    text={
                      shippingNoteForm.shippingNote.batch
                        ? shippingNoteForm.shippingNote.batch.name
                        : i18n.t('views.shipping_note.shipping_note_registration.choose_batch')
                    }
                    style={lightGreenBorderWhiteBGButton}
                    onSubmit={() => setShowBatchModalSelect(true)}
                    disabled={shippingNoteIsClosed(props.shippingNoteSelectedDTO)}
                  />

                  {/**Packet batch  */}
                  {showBatchModalSelect ? (
                    <UrstammModalSelect
                      testID={'modal_shipping_note_company_batch'}
                      visible={showBatchModalSelect}
                      text={{
                        textInputTitle: i18n.t('views.shipping_note.shipping_note_registration.choose_batch'),
                        textStyle: UrstammStyle.textTitle
                        //textInputSubTitle: i18n.t('views.batch.batch_registration.shipping_note_selected')+ ': ' + getCounterByPacketSelected()
                      }}
                      closeModal={() => {
                        setShowBatchModalSelect(false);
                      }}
                      confirmButton={{
                        enabled: false,
                        text: i18n.t('views.shipping_note.no_urstamm_batch'),
                        submitValue: () => {}
                      }}
                      listJSX={
                        <UrstammList
                          titleKey={'name'}
                          elementSelected={(batch: any) => {
                            updateBatch(batch.item);
                          }}
                          showHash={false}
                          testID={'batch_list'}
                          titleByFunction={data => getCardTitleByBatchOrPacket(data)}
                          textForEmptyList={i18n.t('views.shipping_note.batches_not_found')}
                          listSubData={batchKeys}
                          list={props.batchList}
                          listStyle={{
                            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                            bottomContainerViewStyle: UrstammStyleList.cardSubData,
                            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                            paddingBottom: 4
                          }}
                          currentState={{
                            icon: state => getIconByBatchState(state),
                            color: state => getColorByBatchState(state),
                            text: state => getTextByBatchState(state)!
                          }}
                          //listSubData={keys}
                          filterList={true}
                          multipleSelection={false}
                        />
                      }
                    />
                  ) : null}
                </View>

                {/** Processor list */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  {/**Title */}
                  <View style={UrstammStyle.textTitleContainerFlex}>
                    <Text style={UrstammStyle.textTitle}>{i18n.t('generics.processor')}</Text>
                  </View>

                  <UrstammButtonBase
                    testID={'choose_processor'}
                    text={getProcessorName()}
                    style={lightGreenBorderWhiteBGButton}
                    onSubmit={() => setShowProcessorModalSelect(true)}
                    disabled={shippingNoteIsClosed(props.shippingNoteSelectedDTO)}
                  />

                  {/**Packet processor  */}
                  {showProcessorModalSelect ? (
                    <UrstammModalSelect
                      testID={'modal_shipping_note_company_processor'}
                      visible={showProcessorModalSelect}
                      text={{
                        textInputTitle: i18n.t('views.shipping_note.shipping_note_registration.choose_processor'),
                        textStyle: UrstammStyle.textTitle
                        //textInputSubTitle: i18n.t('views.batch.batch_registration.shipping_note_selected')+ ': ' + getCounterByPacketSelected()
                      }}
                      closeModal={() => {
                        setShowProcessorModalSelect(false);
                      }}
                      confirmButton={{
                        enabled: false,
                        text: i18n.t('views.shipping_note.no_urstamm_processor'),
                        submitValue: () => {}
                      }}
                      listJSX={
                        <UrstammList
                          titleKey={'name'}
                          elementSelected={(processor: any) => {
                            updateProcessor(processor.item);
                          }}
                          showHash={false}
                          testID={'processor_list'}
                          textForEmptyList={i18n.t('views.shipping_note.processors_not_found')}
                          list={props.processorList}
                          listStyle={{
                            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                            bottomContainerViewStyle: UrstammStyleList.cardSubData,
                            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                            paddingBottom: 4
                          }}
                          //listSubData={keys}
                          filterList={true}
                          multipleSelection={false}
                        />
                      }
                    />
                  ) : null}
                </View>

                {/** Packets list */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  {/**Title */}
                  <View style={UrstammStyle.textTitleContainerFlex}>
                    <Text style={UrstammStyle.textTitle}>
                      {i18n.t('views.shipping_note.shipping_note_registration.choose_packets') +
                        getMandatorySymbol(false)}
                    </Text>
                  </View>

                  <UrstammButtonBase
                    testID={'choose_packets'}
                    text={i18n.t('views.shipping_note.shipping_note_registration.choose_packets')}
                    style={lightGreenBorderWhiteBGButton}
                    onSubmit={() => setShowPacketSelect(true)}
                    counter={getCounterBySelected(props.customPacketList)}
                    disabled={shippingNoteIsClosed(props.shippingNoteSelectedDTO)}
                  />

                  {/**Packets multiple select */}
                  {showPacketSelect ? (
                    <UrstammModalSelect
                      testID={'modal_shippingNote_multiple_select'}
                      visible={showPacketSelect}
                      text={{
                        textInputTitle: i18n.t('views.shipping_note.shipping_note_registration.choose_packets'),
                        textStyle: UrstammStyle.textTitle
                      }}
                      closeModal={() => {
                        setShowPacketSelect(false), props.resetPacketSelected ? props.resetPacketSelected() : null;
                      }}
                      confirmButton={{
                        enabled: true,
                        text: i18n.t('generics.save'),
                        disabled: props.customPacketList?.length == 0 ? true : false,
                        submitValue: () => {
                          setShowPacketSelect(false);
                          props.confirmPacketSelected ? props.confirmPacketSelected() : null;
                        }
                      }}
                      listJSX={
                        <UrstammList
                          elementSelected={(packetSelected: any) => {
                            props.packetSelected(packetSelected), saveButtonDisabled(errors, shippingNoteForm);
                          }}
                          showHash={false}
                          testID={'packet_list'}
                          textForEmptyList={i18n.t('views.packet.packets_not_found')}
                          titleByFunction={data => getCardTitleByBatchOrPacket(data)}
                          list={props.customPacketList}
                          listStyle={{
                            mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                            mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                            mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                            bottomContainerViewStyle: UrstammStyleList.cardSubData,
                            bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                            paddingBottom: 4
                          }}
                          listSubData={dsKeys}
                          moreItems={props.moreItems}
                          filterList={true}
                          filterListFunction={(text, data) =>
                            filterPacketByNameOrUniqueIdentifierOrProductTypeOrProcessor(text, data, props.userExtendedMe!)
                          }
                          multipleSelection={true}
                          currentState={{
                            //icon: (state) => getIconByLoggingState(state),
                            color: state => getColorByPacketState(state),
                            text: state => getTextByPacketState(state)
                          }}
                          titleKey={'name'}
                          getTextForData={(data, key) => getTextByPacketState(data)}
                        />
                      }
                    />
                  ) : null}
                </View>

                {/** Comment */}
                <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                  <UrstammInput
                    testID="shippingNote_comment"
                    style={{
                      containerViewStyle: UrstammStyleContainer.textareaContainer,
                      textStyle: UrstammStyle.formBaseLDarkGrey
                    }}
                    icon={{ showIcon: false }}
                    value={shippingNoteForm.shippingNote.comment ? shippingNoteForm.shippingNote.comment : ''}
                    onChangeText={(text: string) => updateShippingNoteForm('comment', text, false)}
                    placeholder={i18n.t('generics.insert_comment_here')}
                    textInputTitle={i18n.t('generics.comment')}
                    mandatory={false}
                    multiline={true}
                    editable={!shippingNoteIsClosed(props.shippingNoteSelectedDTO)}
                  />
                </View>

                {!shippingNoteIsClosed(props.shippingNoteSelectedDTO) ? (
                  <View style={[UrstammStyleLayout.formElementContainerGrw1]}>
                    <UrstammButtonBase
                      testID={'save_packet'}
                      text={i18n.t('generics.save')}
                      style={mainGreenButton}
                      onSubmit={() => props.updateShippingNote(shippingNoteForm.shippingNote)}
                      disabled={saveDisabled}
                    />
                  </View>
                ) : null}
              </ScrollView>
            </Collapsible>
          </View>

          {openCloseShippingNoteModal && (
            <UrstammModal
              testID={'send_shipping_note_modal'}
              text={i18n.t('views.shipping_note.are_you_sure_want_send_shipping_note')}
              textStyle={UrstammStyleModal.formBtextModalStyle}
              visible={openCloseShippingNoteModal}
              buttonText={{
                left: i18n.t('utility_components.modal.cancel'),
                right: i18n.t('utility_components.modal.confirm')
              }}
              confirm={(confirmed: boolean) => {
                setOpenCloseShippingNoteModal(false);
                if (
                  confirmed &&
                  props.shippingNoteSelectedDTO.processor != null &&
                  !isCompanyMock(props.shippingNoteSelectedDTO.processor)
                ) {
                  props.closingShippingNote(props.shippingNoteSelectedDTO);
                } else if (confirmed) {
                  setExternalCompany({});
                  setOpenClosingShippingNoteWithExternalCompanyModal(true);
                }
              }}
            />
          )}

          {openClosingShippingNoteWithExternalCompanyModal && (
            <UrstammModal
              testID={'pdf_modal'}
              text={i18n.t('generics.processor')}
              textStyle={UrstammStyleModal.formBtextModalStyle}
              visible={openClosingShippingNoteWithExternalCompanyModal}
              buttonText={{
                left: i18n.t('utility_components.modal.cancel'),
                right: i18n.t('utility_components.modal.confirm')
              }}
              externalCompanyInput={true}
              onChangeExternalCompany={(text, key) => {
                externalCompany[key] = text;
                setExternalCompany(externalCompany);
              }}
              confirm={(confirmed: boolean) => {
                confirmed ? props.closingShippingNote(props.shippingNoteSelectedDTO, externalCompany) : null,
                  setOpenClosingShippingNoteWithExternalCompanyModal(false);
              }}
            />
          )}
        </View>

        {/** Packet list view */}
        {shippingNoteSelected ? (
          <View style={UrstammStyle.flex1}>
            <UrstammList
              elementSelected={(packetSelected: any) => {
                props.packetListProps?.packetSelected?.(packetSelected?.item)
              }}
              showHash={false}
              testID={'packet_list'}
              textForEmptyList={i18n.t('views.packet.packets_not_found')}
              titleByFunction={data => getCardTitleByBatchOrPacket(data)}
              list={shippingNoteSelected.packets || []}
              listStyle={{
                mainTopContainerViewStyle: UrstammStyleList.mainTopContainerCol,
                mainBottomContainerViewStyle: UrstammStyleList.cardSubContainerPDT12,
                mainTitleTextStyle: UrstammStyleList.xCardMainTitleTextStyle,
                bottomContainerViewStyle: UrstammStyleList.cardSubData,
                bottomDataTextStyle: UrstammStyleList.cardSubTitleTextStyle,
                paddingBottom: 4
              }}
              listSubData={pkKeys}
              filterList={true}
              filterListFunction={(text, data) =>
                filterPacketByNameOrUniqueIdentifierOrProductTypeOrProcessor(text, data, props.userExtendedMe!)
              }
              currentState={{
                //icon: (state) => getIconByLoggingState(state),
                color: state => getColorByPacketState(state),
                text: state => getTextByPacketState(state)
              }}
            />
          </View>
        ) : null}
      </ScrollView>
    </View>
  );
}
